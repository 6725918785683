import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { toast } from 'react-toastify';


export const getReports = createAsyncThunk('report/getReports',
    async ({ token, success, page }, thunkAPI) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/reports`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            success();

            return response.data?.data
        }
        catch (err) {
            toast.error(err.response?.data?.msg);
            console.log(err.response?.data?.msg);
        }
    })


const initialState = {
    report: {
        transaction: {
            total: 0,
            average: 0,
            profit: 0,
            low: 0,
            high: 0
        },
        member: {
            total: 0,
            gender: {
                men: 0,
                female: 0,
            },
            us: 0,
            accountTypes: {
                personal: 0,
                gold: 0,
                business: 0
            },
            eigteenToTwenty: 0,
            balances: {
                high: 0,
                low: 0
            }
        }
    }
};

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {},
    extraReducers: {
        [getReports.fulfilled]: (state, { payload }) => {
            state.report = payload
        },
    }
})

export const reportSelector = state => state.report
export default reportSlice.reducer