import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './styles/App.css';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import Login from './pages/login';
import Forgot from './pages/forgot-password';
import Dashboard from './pages';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={ <Login /> } />
        <Route path='dashboard' element={ <Dashboard/> } />
        <Route path="forgot-password" element={ <Forgot /> } />
      </>
    )
  );

  return <RouterProvider router={ router }/>
  
}

export default App;
