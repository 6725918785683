import React from 'react'
import Nav from '../reusables/nav';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import TransactionList from '../transactions/transactionList';
import ExchangeList from '../transactions/exchangeList';

function Transactions() {
    return (
        <div className="container__custom">
            <Nav pageName="Transactions" />
            <Tabs>
                <div className="spaceBetween mb-5">

                    <div className="">
                        <TabList className='tab__button alignOnly p-0 mb-0'>
                            <Tab className='mx-2'>
                                Transactions
                            </Tab>
                            <Tab className='mx-2'>
                                Exchange
                            </Tab>
                        </TabList>
                    </div>

                    <div className="card__custom alignOnly px-5">
                        <div className="sectionText mx-3">
                            <p className="mb-0 text__light">Processing Value</p>
                            <p className="text__bold mb-0">£ 2456,00</p>
                        </div>
                        <div className="sectionText mx-3">
                            <p className="mb-0 text__light">24H Volume</p>
                            <p className="text__bold mb-0">£ 8,524,250</p>
                        </div>
                        <div className="sectionText mx-3">
                            <p className="mb-0 text__light">Estimate Profits</p>
                            <p className="text__bold mb-0">£ 524,250</p>
                        </div>
                    </div>
                </div>

                <TabPanel>
                    <TransactionList/>
                </TabPanel>
                <TabPanel>
                    <ExchangeList />
                </TabPanel>
                
            </Tabs>
            
        </div>
      
    )
}

export default Transactions
