import React from 'react'
import { useState } from 'react'

function Switcher({ activate, deactivate }) {
    const [active, setActive] = useState(true);

    const setTrue = () => {
        setActive(true);
        // activate();
    }

    const setFalse = () => {
        setActive(false);
        // deactivate();
    }

    return (
        <div className='alignOnly'>
            <div className={ `switcher__option ${active === true && 'active'} clickable` } onClick={ setTrue }>
                <p className="sectionText__mid mb-0">True</p>
            </div>
            <div className={ `switcher__option clickable ${active === false && 'active'}` } onClick={ setFalse }>
                <p className="sectionText__mid mb-0">False</p>
            </div>
        </div>
    )
}

export default Switcher
