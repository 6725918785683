import React, { useEffect, useState } from "react";
import Nav from "../reusables/nav";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { getAdminData, userSelector } from "../../redux/slices/userSlice";
import Chart from "react-apexcharts";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const data = {
  chart: {
    id: "basic-line",
  },
  xaxis: {
    categories: labels,
  },
};

function Home() {
  const dispatch = useDispatch();
  let token = localStorage.getItem("token");
  const { adminData } = useSelector(userSelector);
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
      },
    },
    series: [
      {
        name: "Transactions",
        data: [0, 0, 0, 1100, 1500, 1300, 900, 2000, 1800, 1800, 2000, 3300],
      },
    ],
  });

  useEffect(() => {
    dispatch(getAdminData(token));
  }, []);

  return (
    <div className="">
      <div className="container__custom">
        <Nav pageName="Dashboard" />
        <div className="row">
          <div className="col-lg-4  col-md-6  my-4">
            <div className="card__custom spaceBetween">
              <div className="sectionText__mid text__light">
                <p className="mb-0">Total Users</p>
                <p className="mb-0">
                  <span className="text__bold">{adminData?.totalUsers}</span>
                  <span className="mx-2">
                    Account{adminData?.totalUsers > 1 && "s"}
                  </span>
                </p>
              </div>

              <div className="gradient__linear center icon__holder">
                <i className="bi bi-person-fill sectionText__big text__white"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6  my-4">
            <div className="card__custom spaceBetween">
              <div className="sectionText__mid text__light">
                <p className="mb-0">Total Transactions</p>
                <p className="mb-0 text__bold">
                  £ {adminData?.totalTransactions}
                </p>
              </div>

              <div className="gradient__linear center icon__holder">
                <i className="bi bi-currency-exchange sectionText__big text__white"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-4  col-md-6 my-4">
            <div className="card__custom spaceBetween">
              <div className="sectionText__mid text__light">
                <p className="mb-0">Total Balance</p>
                <p className="mb-0 text__bold">£ {adminData?.totalBalance}</p>
              </div>

              <div className="gradient__linear center icon__holder">
                <i className="bi bi-wallet-fill sectionText__big text__white"></i>
              </div>
            </div>
          </div>
        </div>

        <div className="card__custom sectionHeight">
          <div className="hasBorder__bottom">
            <p className="sectionText__mid py-3 mb-0 text__light">
              Transaction Report
            </p>
          </div>
          <div className="py-4">
            <Chart
              options={chartData.options}
              series={chartData.series}
              type="line"
              height="500"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
