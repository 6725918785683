import React from "react";
import "../../styles/auth.css";
import country_list from "../../utils/countryList";
function InputField({
  type,
  label,
  placeholder,
  name,
  value,
  handleChange,
  disabled,
  className,
  inputClass,
  textarea,
  countryOptions,
}) {
  return (
    <div className="py-2">
      {label && <p className="mb-2 sectionText text__light">{label}</p>}
      <div
        className={`inputHolder ${textarea && "textarea"} ${
          disabled && "disabled"
        } ${className}`}
      >
        {textarea ? (
          <textarea
            rows="6"
            className="w-100"
            name={name}
            placeholder={placeholder}
            onChange={handleChange}
            value={value}
          />
        ) : name === "country" ? (
          <select
            name={name}
            onChange={handleChange}
            value={value}
            className={inputClass}
          >
            <option value="" disabled>
              {placeholder}
            </option>
            {country_list.map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select>
        ) : (
          <input
            type={type}
            name={name}
            placeholder={placeholder}
            onChange={handleChange}
            value={value}
            disabled={disabled}
            className={inputClass}
          />
        )}
      </div>
    </div>
  );
}

export default InputField;
