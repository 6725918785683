import React from 'react'

function FeeInput({ reduce, add, value, className, setupFee}) {
    return (
        <div className={ className }>
            { setupFee
                ?
                <p className="sectionText mb-1">Setup Fee</p>
                :
                <div className="spaceBetween sectionText mb-1">
                    <p className="mb-0">Fee</p>
                    <p className="mb-0">per month</p>
                </div>
            }
    
            <div className="input__fee spaceBetween sectionText">
                <i className='bi bi-dash-lg clickable' onClick={ reduce}></i>
                <p className='mb-0'>{ value }</p>
                <i className="bi bi-plus-lg clickable" onClick={add}></i>
            </div>
        </div>
    )
}

export default FeeInput
