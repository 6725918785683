import { configureStore } from '@reduxjs/toolkit';
import accountSlice from './slices/accountSlice';
import authSlice from './slices/authSlice';
import reportSlice from './slices/reportSlice';
import userSlice from './slices/userSlice';

export const store = configureStore({
    reducer: {
        auth: authSlice,
        account: accountSlice,
        user: userSlice,
        report: reportSlice
    },
});
