import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import InputField from '../components/reusables/input';
import { resetPassword } from '../redux/slices/authSlice';
import Spinner from '../components/reusables/spinner';
import '../styles/auth.css';

function Forgot() {

    const dispatch = useDispatch();

    const [state, setState] = useState({
        field: ''
    })
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        dispatch(resetPassword({ state, success: () => setLoading(false) }));
    }

    return (
        <div className='auth__body center'>
            <div className="auth__card">
                <div className="auth__card-head">
                    <div className="imgHolder center">
                        <img src="/images/logo.png" className='w-100' alt="" />
                    </div>
                </div>
                <div className="w-100 my-4">

                    <form onSubmit={ handleSubmit }>
                        <h2 className="sectionHead__mid pb-3">Forgot Password</h2>

                        <InputField placeholder='Email' label='Enter recovery email' value={ state.value }
                            handleChange={ (e) => setState({
                                field: e.target.value
                            }) }
                        />

                        <div className="py-4">
                            <button className='btn__default sectionText w-100 center' disabled={ loading }>
                                {
                                    loading ?
                                        <Spinner />
                                        :
                                        'Recover Password'
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Forgot
