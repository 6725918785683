import React, { useState } from 'react'
import Pagination from 'react-bootstrap/Pagination';

function CustomPaginate({totalPages, activePage, handlePageChange}) {

    const pages = [];
    
    for (var i = 1; i <= totalPages; i++){
        pages.push(i);
    }

    

    return (
        <div className="center">
            <Pagination>
                <Pagination.First onClick={() => handlePageChange(1)} disabled={ activePage === 1 }/>
                <Pagination.Prev onClick={ () => handlePageChange(activePage - 1) } disabled={ activePage === 1 }/>

                {
                    pages.map(number => (
                        <Pagination.Item key={number} onClick={() => handlePageChange(number)} active={activePage === number}>{ number }
                        </Pagination.Item>
                    ))
                }
               
                <Pagination.Next onClick={ () => handlePageChange(activePage + 1) } disabled={ activePage === totalPages } />
                <Pagination.Last onClick={ () => handlePageChange(totalPages) } disabled={ activePage === totalPages } />
            </Pagination>
        </div>
    );
}

export default CustomPaginate;