import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { createAccount } from "../../redux/slices/accountSlice";
import Dropdown from "./dropdown";
import InputField from "./input";
import Spinner from "./spinner";

function CreateAccountModal({ show, handleClose, user }) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    name: {
      first: "",
      last: "",
    },
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleNameChange = (e) => {
    setState({
      ...state,
      name: {
        ...state.name,
        [e.target.name]: e.target.value,
      },
    });

    console.log(state);
  };

  const success = () => {
    setLoading(false);
    handleClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(createAccount({ state, token, success, error: success }));
  };

  const genderOptions = ["male", "female"];

  return (
    <Modal centered show={show} onHide={handleClose} className="modal__custom">
      <div className="p-4">
        <form onSubmit={handleSubmit}>
          <div className="py-2">
            <InputField
              label="First Name"
              name="first"
              placeholder="Enter first name"
              handleChange={handleNameChange}
              value={state.name.first}
            />
          </div>
          <div className="py-2">
            <InputField
              label="Last Name"
              name="last"
              placeholder="Enter last name"
              handleChange={handleNameChange}
              value={state.name.last}
            />
          </div>
          <div className="py-2">
            <InputField
              label="Phone Number"
              name="phoneNumber"
              placeholder="Enter phone number"
              handleChange={handleChange}
              value={state?.phoneNumber}
            />
          </div>
          <div className="py-2">
            <Dropdown
              long
              value={state?.gender || "Click to select"}
              options={genderOptions}
              label="Gender"
              borderOnly
              onChange={(item) =>
                setState({
                  ...state,
                  gender: item,
                })
              }
            />
          </div>
          <div className="py-2">
            <InputField
              label="Email"
              name="email"
              placeholder="Enter email"
              handleChange={handleChange}
              value={state?.email}
            />
          </div>
          <div className="py-2">
            <InputField
              label="Date Of Birth"
              type="date"
              placeholder="Select date"
              handleChange={handleChange}
              value={state?.dob}
              name="dob"
            />
          </div>
          <div className="py-2">
            <InputField
              label="Password"
              name="password"
              placeholder="Enter password"
              handleChange={handleChange}
              value={state?.password}
            />
          </div>
          <div className="py-2">
            <InputField
              label="Country"
              name="country"
              placeholder="Select country"
              handleChange={handleChange}
              value={state?.country}
            />
          </div>
          <div className="py-2">
            <InputField
              label="State"
              name="state"
              placeholder="Enter state"
              handleChange={handleChange}
              value={state?.state}
            />
          </div>
          <div className="py-2">
            <InputField
              label="City"
              name="city"
              placeholder="Enter city"
              handleChange={handleChange}
              value={state?.city}
            />
          </div>
          <div className="py-2">
            <InputField
              label="Zip Code"
              name="zipCode"
              placeholder="Enter zip code"
              handleChange={handleChange}
              value={state?.zipCode}
            />
          </div>
          <div className="py-2">
            <InputField
              label="Address"
              name="address"
              placeholder="Enter address"
              handleChange={handleChange}
              value={state?.address}
            />
          </div>

          <div className="py-4">
            <button className="btn__default center w-100" disabled={loading}>
              {loading ? <Spinner /> : "Add User"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default CreateAccountModal;
