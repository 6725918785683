import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Dropdown from '../reusables/dropdown'
import InputField from '../reusables/input';

function ReportResult({show, handleClose}) {

    const [state, setState] = useState({
        show: 'Group of Age',
        value: 'Global',
        duration: {
            from:
                "Sept 2021",
            to:
                "Sept 2022",
        }
    })

    let groupOptions = [
        "Group of Age",
        "Gender",
        "New Member",
        "Active Member"
    ]

    let valueOptions = [
        "Global",
        "Time Zone",
        "Continent",
        "Country"
    ]

    return (
        <Modal show={ show } onHide={ handleClose } centered className='modal__custom lg modal__report'>
            <div className="hasBorder__bottom sectionText__mid text__light py-4">
                <div className="container spaceBetween">
                    <i className="bi bi-chevron-left sectionHead__mid clickable"></i>
                    <p className="center sectionText__big mb-0">Quick Report Generated</p>

                    <button className="btn__default mx-2">Export to XLS</button>
                </div>
            </div>

            <div className="py-4 hasBorder__bottom container">

                <div className="spaceBetween">
                    <div className="my-2">
                        <div className="d-flex">
                            <Dropdown label="What do you want to show?" value={ state.show } options={ groupOptions } className='' />
                            <Dropdown label="Select Value" value={ state.value } options={ valueOptions } className='mx-4' />
                        </div>
                    </div>

                    <div className="my-2">
                        <div className="alignOnly">

                            <div className="mx-2">
                                <InputField label="From:" value={ state.duration.from } type="date" />
                            </div>

                            <div className="mx-2">
                                <InputField label="To:" value={ state.duration.to } type="date" />
                            </div>

                            <div className="mx-3">
                                <div className="alignOnly mb-3">
                                    <input type="checkbox" className='checkbox__custom' />
                                    <p className="sectionText__mid text__light mb-0 mx-3">Lifetime</p>
                                </div>

                                <button className="btn__default">
                                    <span className="px-3">Apply</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="sectionHeight">
                <div className="row">
                    <div className="col-md-5"></div>
                    <div className="col-md-7">

                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ReportResult