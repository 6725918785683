import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { accountSelector, getNotifications } from '../../redux/slices/accountSlice'

function NotiList({ name }) {
    const dispatch = useDispatch();
    const {broadcasts} = useSelector(accountSelector);
    const token = localStorage.getItem('token');

    console.log(broadcasts);

    useEffect(() => {
        dispatch(getNotifications({token}));
    }, []);
    

    return (
        <div> 
            <p className="sectionText__big text__light hasBorder__bottom">{ name } {' '} List</p>

            <table class="table transactions pt-4">
                <thead className='sectionText__mid'>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Title</th>
                        <th scope="col">Description</th>
                        <th scope="col">Reciever</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='sectionText text__light'>
                        <td>1</td>
                        <td>
                            Use Morizon Wallet App
                        </td>
                        <td>Lorem ipsum dolor sit amet, consectetur ….....</td>
                        <td>All</td>
                        <td>
                            <div className="mt-1">
                                <span className="table__actionBtn mx-2">
                                    <i className="bi bi-pencil-square"></i>
                                </span>

                                <span className="table__actionBtn mx-2">
                                    <i className="bi bi-trash3"></i>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr className='sectionText text__light'>
                        <td>2</td>
                        <td>
                            Use Morizon Wallet App
                        </td>
                        <td>Lorem ipsum dolor sit amet, consectetur ….....</td>
                        <td>Personal Account</td>
                        <td>
                            <div className="mt-1">
                                <span className="table__actionBtn mx-2">
                                    <i className="bi bi-pencil-square"></i>
                                </span>

                                <span className="table__actionBtn mx-2">
                                    <i className="bi bi-trash3"></i>
                                </span>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>

        </div>
    )
}

export default NotiList
