import React, {useState} from 'react'
import Nav from '../reusables/nav.js';
import {Carousel} from 'react-bootstrap';
import Home from '../reports';
import TransactionDetail from '../reports/transactionDetail';
import Demographic from '../reports/demographic.js';

function Reports() {

    const [title,
        setTitle] = useState('Reports');
    const [index,
        setIndex] = useState(0);


    const goToSlide = (slide) => {
        setIndex(slide);
    }

    const nextSlide = () => {
        goToSlide(1);
        setTitle('Transaction Breakdown')
    }

    const prevSlide = () => {
        goToSlide(0);
        setTitle('Reports')
    }

    const lastSlide = () => {
        goToSlide(2);
        setTitle("Demographic Report");
    }

    return (
        <div className='container__custom'>
            <Nav pageName={title}/>
            <Carousel activeIndex={index} controls={false} indicators={false}>
                <Carousel.Item>
                    <Home nextSlide={nextSlide} lastSlide={lastSlide}/>
                </Carousel.Item>
                <Carousel.Item>
                    <TransactionDetail
                        prevSlide={prevSlide}/>
                </Carousel.Item>
                <Carousel.Item>
                    <Demographic prevSlide={prevSlide}/>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default Reports