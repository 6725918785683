import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { accountTypes } from '../../utils';
import Dropdown from '../reusables/dropdown';


let reportOptions = ["Transactions", "Transfers"];


function CompareStatsModal({ show, handleClose }) {

    const [state,
        setState] = useState({
            stats: [
                {
                    label: 'Stats 1:',
                    options: accountTypes,
                    value: accountTypes[0]
                }, {
                    label: 'Stats 2:',
                    options: reportOptions,
                    value: reportOptions[0]
                }
            ],
            duration: {
                from: {
                    day: '01',
                    month: "September",
                    year: "2021"
                },
                to: {
                    day: '01',
                    month: "September",
                    year: "2022"
                }
            }
        });

    const updateStat = (stat) => {
        let selectedStat = state
            .stats
            .find(item => item === stat);
        selectedStat = {
            ...selectedStat,
            ...stat
        }
        setState({
            ...state,
            stats: [
                ...state.stats,
                selectedStat
            ]
        });
    }

    return (
        <Modal show={ show } onHide={ handleClose } centered>
            <div className="hasBorder__bottom sectionText__mid text__light py-4">
                <p className="center mb-0">Compare Stats</p>
                <i
                    className="rAlign bi bi-x-lg sectionText__big icon__close clickable"
                    onClick={ handleClose }></i>
            </div>

            <div className="container px-4 py-3">
                <form>
                    { state.stats
                        ?.map(item => (
                            <div className="my-4">
                                <Dropdown
                                    label={ item.label }
                                    options={ item.options }
                                    value={ item.value }
                                    long
                                    onChange={ () => updateStat(item) } />
                            </div>
                        ))
                    }

                    <div className="my-4">
                        <button className="btn__addStat w-100">
                            <i className='bi bi-plus-lg mx-2'></i>
                            Add Stats</button>
                    </div>

                    <div className="my-4">
                        <Dropdown
                            label="Type:"
                            options={ accountTypes }
                            value={ state.type }
                            long
                            onChange={ item => setState({
                                ...state,
                                type: item
                            }) } />
                    </div>

                    <div className="my-4">
                        <p className="sectionText mb-2  text__light">From</p>
                        <div className="alignOnly">
                            <Dropdown value={ state.duration.from.day } />
                            <Dropdown value={ state.duration.from.month } className='mx-2' />
                            <Dropdown value={ state.duration.from.year } />
                        </div>
                    </div>

                    <div className="my-4">
                        <p className="sectionText mb-2 text__light">To</p>
                        <div className="alignOnly">
                            <Dropdown value={ state.duration.to.day } />
                            <Dropdown value={ state.duration.to.month } className='mx-2' />
                            <Dropdown value={ state.duration.to.year } />
                        </div>
                    </div>

                    <button className="mt-3 btn__default w-100">GENERATE CHART</button>
                </form>
            </div>
        </Modal>
    )
}

export default CompareStatsModal




export const ResultsModal = ({showResult, hideResult}) => {
    let state = {
        stats: [
            {
                label: 'Stats 1:',
                options: accountTypes,
                value: accountTypes[0],
                color: 'blue'
            }, {
                label: 'Stats 2:',
                options: reportOptions,
                value: reportOptions[0],
                color: 'green'
            }
        ],
        duration: {
            from: {
                day: '01',
                month: "September",
                year: "2021"
            },
            to: {
                day: '01',
                month: "September",
                year: "2022"
            }
        }
    }

    return (
        <Modal show={ showResult } onHide={ hideResult } centered className='modal__custom lg'>
            <div className="hasBorder__bottom sectionText__mid text__light py-4">
                <p className="center mb-0">Compare Stats</p>
                <i
                    className="rAlign bi bi-x-lg sectionText__big icon__close clickable"
                    onClick={ hideResult }></i>
            </div>

            <div className="container__custom">
                <div className="spaceBetween">
                    <div className="alignOnly">
                        { state
                            .stats
                            .map(item => (<Dropdown
                                label={ item.label }
                                value={ item.value }
                                checkbox
                                checkboxColor={ item.color } />)) }
                    </div>
                    <div className="d-flex">
                        <div className="mx-3">
                            <p className="sectionText mb-1">From:</p>
                            <div className="alignOnly">
                                <Dropdown value={ state.duration.to.day } />
                                <Dropdown value={ state.duration.to.month } className='mx-2' />
                                <Dropdown value={ state.duration.to.year } />
                            </div>
                        </div>

                        <div className="mx-3">
                            <p className="sectionText mb-1">To:</p>
                            <div className="alignOnly">
                                <Dropdown value={ state.duration.to.day } />
                                <Dropdown value={ state.duration.to.month } className='mx-2' />
                                <Dropdown value={ state.duration.to.year } />
                            </div>
                        </div>

                     
                    </div>
                </div>
                <button className="btn__addStat mt-4">
                    <i className='bi bi-plus-lg mx-2'></i>
                    Add Stats</button>

                <button className="btn__default mx-2">APPLY</button>

                    <div className="chart__holder">

                    </div>
            </div>
        </Modal>
    )
}