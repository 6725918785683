import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { accountSelector, getTransactions } from '../../redux/slices/accountSlice';
import CustomPaginate from '../reusables/customPaginate';
import Spinner from '../reusables/spinner';


function AccountTransactions({ id }) {

    const dispatch = useDispatch();
    const { transactions } = useSelector(accountSelector);
    const [loading,
        setLoading] = useState(true);
    const [typeLoader, setLoader] = useState(false);
    const token = localStorage.getItem('token');


    const handlePageChange = (pageNumber) => {
        console.log(pageNumber);
        setLoader(true);
        dispatch(getTransactions({ token, success: () => setLoading(false), page: pageNumber, id }));
    };

    console.log(transactions);

    useEffect(() => {
        dispatch(getTransactions({
            token,
            id,
            success: () => setLoading(false)
        }))
    }, [id]);

    return (
        <div className="">
            { loading
                ? <div className="center sectionHeight">
                    <Spinner dark />
                </div>
                :
                <div className="pt-4 card__custom">
                    <Tabs>
                        <TabList className='tab__button alignOnly pb-3'>
                            <Tab className='mx-2'>
                                Transfer
                            </Tab>
                            <Tab className='mx-2'>
                                Detail
                            </Tab>
                            <Tab className='mx-2'>
                                Withdraw
                            </Tab>
                        </TabList>

                        <TabPanel>
                            {
                                typeLoader
                                    ?
                                    <div className="center py-5">
                                        <Spinner dark />
                                    </div>
                                    :
                                    <>
                                    <table class="table transactions">
                                        <thead className='sectionText__mid'>
                                            <tr className=''>
                                                <th scope="col" className="text__light">Transaction ID</th>
                                                <th scope="col" className="text__light">Today</th>
                                                <th scope="col" className="text__light">Method</th>
                                                <th scope="col" className="text__light">Amount</th>
                                                <th scope="col" className="text__light">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>{ (transactions?.results || []).map(transaction => {
                                            return <tr className='sectionText text__light'>
                                                <td>{ transaction.ref }</td>
                                                <td>
                                                    { transaction.createdAt }
                                                </td>
                                                <td>{ transaction.category }</td>
                                                <td>$ { transaction.amount }</td>
                                                <td><span className={ transaction.status == 'completed' ? 'text__green' : 'text__mustard' }>{ transaction.status }</span></td>
                                            </tr>
                                        }) }
                                        </tbody>
                                    </table>
                                        <CustomPaginate totalPages={ transactions?.totalPages } activePage={ transactions?.page } handlePageChange={ (pageNumber) => handlePageChange(pageNumber) } />
                                    </>
                            }
                        </TabPanel>
                    </Tabs>
                </div>
            }
        </div>
    )
}

export default AccountTransactions
