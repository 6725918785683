import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { toast } from 'react-toastify';


export const login = createAsyncThunk('auth/login',
    async ({ state, success, error }, thunkAPI) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/login`, state);
            console.log(response)

            toast.success(response.data?.msg);
            localStorage.setItem("token", response.data?.data?.token?.token);

            success();

            return response.data?.data

        }
        catch (err) {
            toast.error(err.response?.data?.msg);
            error();
        }
    })

export const resetPassword = createAsyncThunk('auth/password-reset', 
    async({state, success}, thunkAPI) => {
        try{
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/password/reset`, state);
            console.log(response);

            toast.success(response.data?.msg);
            success();
        }
        catch(err) {
            toast.error(err.response?.data?.msg);
            console.log(err.response?.data?.msg);
        }
    })

export const logout = createAsyncThunk('auth/logout', 
async({token, success}, thunkAPI) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/logout`, '', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        console.log(response);
        toast.success(response.data?.msg);
    }
    catch (err) {
        toast.error(err.response?.data?.msg);
        console.log(err.response?.data?.msg);
    }
    success();
});

const initialState = {
    user: {},
    account: {},
    auth: {}
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers:  {
        [login.fulfilled]: (state, {payload}) => {
            state.user = payload.user;
            state.account = payload.account;
            state.auth = payload.authOption;
        }
    }
})

export const authSelector = state => state.auth
export default authSlice.reducer