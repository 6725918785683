import React, { useState } from 'react'

function Dropdown({ value, className, onChange, options, label, long, filter, borderOnly, handleFilter, checkbox }) {

    const [active, setActive] = useState(false);
    const [activeItem, setItem] = useState(value);

    const handleToggle = (item) => {
        setActive(false);
        onChange(item);
        setItem(item);
    }

    return (
        <>
             {active && <div className="backdrop" onClick={() => setActive(false)}></div>}

        <div className={ `isRelative dropdown__holder ${className}` }>
            { label && <p className="sectionText mb-2 text__light">{ label }</p> }
       
                <div className={ `dropdown ${long && 'long'} ${borderOnly && 'outline'}  spaceBetween sectionText clickable` } onClick={ () => setActive(!active) }>
                <p className="mb-0 px-4 py-1 sectionText">{ activeItem }</p>

                <i className={ `bi bi-caret-down text__gray ${active && "flipped"}` }></i>
            </div>
            { active &&
                <div className={ `options ${long && 'w-100'}` }>
                    { options?.map(item => (
                        <div className="alignOnly option" onClick={ () => handleToggle(item) }>

{
    checkbox
     && 
     <div className={ `radio mx-2 ${activeItem === item && 'filled'}` }></div>
}


                            <p className="sectionText mb-0">{ item }</p>
                        </div>
                    )) }

                    { filter &&
                        <button className='btn__default w-100 my-3' onClick={handleFilter}>Apply</button>
                    }
                </div>

            }
        </div>
        </>
    )
}

export default Dropdown
