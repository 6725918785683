import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountSelector, getTransactions } from '../../redux/slices/accountSlice';
import Spinner from '../reusables/spinner';

function TransactionList() {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { transactions } = useSelector(accountSelector);
    const token = localStorage.getItem('token');

    useEffect(() => {
        setTimeout(() => setLoading(false), 10000)
        dispatch(getTransactions({ token, success: () => setLoading(false) }));
    }, []);



    return (
        <div className="card__custom">
            <div className="spaceBetween mb-4">
                <h2 className="sectionText__big">Transaction List</h2>
            </div>

            <div className="pt-4 hasBorder__top">
                {
                    loading
                        ?
                        <div className="center py-5">
                            <Spinner dark />
                        </div>
                        :
                        transactions?.length >= 0
                            ?
                            <table class="table transactions">
                                <thead className='sectionText__mid'>
                                    <tr>
                                        <th scope="col">Transaction ID</th>
                                        <th scope="col">Today</th>
                                        <th scope="col">Transfer</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">QR Id</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='sectionText text__light'>
                                        <td>jUhs7-65vxC-0u7ds-rrE2m</td>
                                        <td>
                                            Just Now
                                        </td>
                                        <td>Send</td>
                                        <td>£ 2456,00</td>
                                        <td>
                                            0x93024092348098
                                        </td>
                                        <td>
                                            <span className="text__mustard">Processing</span>
                                        </td>
                                    </tr>

                                    <tr className='sectionText text__light'>
                                        <td>jUhs7-65vxC-0u7ds-rrE2m</td>
                                        <td>
                                            Just Now
                                        </td>
                                        <td>Send</td>
                                        <td>£ 2456,00</td>
                                        <td>
                                            0x93024092348098
                                        </td>
                                        <td>
                                            <span className="text__mustard">Processing</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            :
                            <div className="center py-5">
                                <p className="sectionText__big text__light text__primary mb-0">No data found</p>
                            </div>
                }
            </div>
        </div>
    )
}

export default TransactionList
