import React, {useState} from 'react'
import Dropdown from '../../components/reusables/dropdown';
import {Line, Bar} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
    BarController
} from 'chart.js';
import ExportModal from './exportModal';
import CompareStatsModal, { ResultsModal } from './compareStatsModal';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, BarElement, BarController);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top'
        }
    },
    scales: {
        x: {
            display: false
        },
        y: {
            display: true
        }
    }
};

const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July'
];

export const lineData = {
    labels,
    datasets: [
        {
            label: 'Transactions',
            borderWidth: 3,
            data: [
                0,
                1100,
                1500,
                1300,
                900,
                2000,
                1800,
                1800,
                2000,
                3300
            ],
            fill: true,
            borderColor: '#0D1CB3',
            tension: 0.4
        }
    ]
};

export const barData = {
    datasets: [
        {
            label: 'bar chart',
            data: [
                0,
                1100,
                1500,
                1300,
                900,
                2000,
                1800,
                1800,
                2000,
                3300
            ],
            backgroundColor: '#0D1CB3',
            borderRadius: 15,
            borderSkipped: false,
        }]
}

function TransactionDetail({ prevSlide }) {

    const [exportModal, setExport] = useState(false);
    const [compareModal, setCompare] = useState(false);

    const [state,
        setState] = useState({
        transTypes: [
            'Personal', 'All'
        ],
        time: [
            '24H', '48H'
        ],
        activeTrans: '',
        activeTime: ''
    });

    const accountsAndStat = [
        {
            logo: '/images/3d_black_card.png',
            accountName: 'Personal Account',
            time: '6 Months',
            profit: '£12,157'
        },
            {
            logo: '/images/3d_golden_card.png',
            accountName: 'Gold Account',
            time: '6 Months',
            profit: '£12,157'
        },
        {
            logo: '/images/3d_blue_card.png',
            accountName: 'Business Account',
            time: '6 Months',
            profit: '£12,157'
        }
    ];

    return (
        <div className='container__custom'>
            <div className="row">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-lg-9 d-flex">
                            <i className="bi bi-chevron-left sectionHead__mid clickable" onClick={prevSlide}></i>
                            <div className="mx-4 w-100">
                                <div className="spaceBetween">
                                    <div className="">
                                        <p className="sectionText mb-0">Profits this month <i className="bi bi-caret-down"></i></p>
                                        <p className="sectionText__mid text__bold mb-2">£ 3,336.90 <span className="tag green">8,37%</span></p>
                                    </div>
                                    <div className="rAlign">
                                        <p className="sectionText mb-0">Lifetime Transactions</p>
                                        <p className="sectionText__mid text__bold mb-2">£ 47,928.00</p>
                                    </div>
                                </div>

                                <div className="spaceBetween">
                                    <div className="">
                                        <p className="sectionText mb-0">Last Month</p>
                                        <p className="sectionText__mid text__bold mb-2">£ 54,601.00</p>
                                    </div>
                                    <div className="rAlign">
                                        <p className="sectionText mb-0">Average</p>
                                        <p className="sectionText__mid text__bold mb-2">£ 54,601.00</p>
                                    </div>
                                </div>

                                <div className="spaceBetween">
                                    <div className="">
                                        <p className="sectionText mb-0">Highest</p>
                                        <p className="sectionText__mid text__bold mb-2">£ 4,601.00</p>
                                    </div>
                                    <div className="rAlign">
                                        <p className="sectionText mb-0">Highest</p>
                                        <p className="sectionText__mid text__bold mb-2">£ 4,601.00</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <button className="my-3 btn__default w-100" onClick={()  => setExport(true)}>
                                Export to xls
                            </button>
                            <button className="my-3 btn__default" onClick={() => setCompare(true)}>
                                Compare Stats
                            </button>
                        </div>
                    </div>

                    <div className="mt-4">
                        <div className="card__custom">
                            <div className="spaceBetween">
                                <p className="sectionText__mid mb-0">All Transaction</p>

                                <div className="alignOnly">
                                    <Dropdown
                                        value="Personal"
                                        options={state.transTypes}
                                        onChange={(item) => setState({
                                        ...state,
                                        activeTrans: item
                                    })}
                                        className='mx-1'/>

                                    <Dropdown
                                        value="Personal"
                                        options={state.transTypes}
                                        onChange={(item) => setState({
                                        ...state,
                                        activeTrans: item
                                    })}
                                        className='mx-1'/>
                                </div>

                            </div>

                            <div className="py-4">
                                <Line data={ lineData } options={ options } />
                            </div>

                            <div className="alignOnly sectionText pt-3">

                                <div className="mx-2">
                                    <p className="mb-0 text__light">High:</p>
                                    <p className="text__bold mb-0 mx-1">£25,689.00</p>
                                </div>

                                <div className="mx-2">
                                    <p className="mb-0 text__light">Low:</p>
                                    <p className="text__bold mb-0 mx-1">£5,689.00</p>
                                </div>

                                <div className="mx-2">
                                    <p className="mb-0 text__light">Est.Profits:</p>
                                    <p className="text__bold mb-0 mx-1">£1,689.00</p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card__custom">
                        <div className="spaceBetween hasBorder__bottom">
                            <div className="sectionText__mid">
                                <p className="mb-0">User Balance</p>
                                <p className="text__bold">£ 47,928.00</p>
                            </div>

                            <div className="">
                                <Dropdown value="24 H"/>
                            </div>
                        </div>

                        <div className="">
                            <Bar data={barData} options={options}/>
                        </div>

                        <div className="alignOnly  mt-4">
                            <div className="">
                                <p className="mb-0 text__light">High:</p>
                                <p className="text__bold mb-0 mx-1">£25,689.00</p>
                            </div>

                            <div className="mx-2">
                                <p className="mb-0 text__light">Low:</p>
                                <p className="text__bold mb-0 mx-1">£5,689.00</p>
                            </div>
                        </div>
                    </div>

                    <div className="card__custom my-5">
                        <div className="spaceBetween hasBorder__bottom pb-3">
                            <div className="sectionText__mid">
                                <p className="mb-0">Account State</p>
                            </div>

                            <div className="">
                                <Dropdown value="24 H"/>
                            </div>
                        </div>

                        <div className="row">
                            {accountsAndStat.map(account => (
                                <div className="col-md-6 d-flex my-3">
                                    <div
                                        className=""
                                        style={ {
                                            width: "50px"
                                        } }>
                                        <img src={account.logo} className="w-100" alt="" />
                                    </div>
                                    <div className="sectionText mx-2">
                                        <p className="mb-1 text__bold">{account.accountName}</p>
                                        <p className='mb-1'>
                                            Avg. Time:
                                            <span className='mx-2'>6 Months</span>
                                        </p>
                                        <p>
                                            Estd. Profit:
                                            <span className='mx-2'>£12,157</span>
                                        </p>
                                    </div>
                                </div>
                            ))}
                        
                        </div>
                    </div>
                </div>
            </div>
            <ExportModal show={ exportModal } handleClose={() => setExport(false)}/>
            <CompareStatsModal show={compareModal} handleClose={() => setCompare(false)}/>
            {/* <ResultsModal showResult={compareModal}/> */}
        </div>
    )
}

export default TransactionDetail