import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { accountSelector, getTransactions, getWallets } from '../../redux/slices/accountSlice';
import { getUserData, getUserTransactions, userSelector } from '../../redux/slices/userSlice';
import { pageChange } from '../../utils';
import Spinner from '../reusables/spinner';
import { createModal } from "../wallet/createModal"
import CustomPaginate from '../reusables/customPaginate';

export default function AccountWallets({ id }) {
    const dispatch = useDispatch();
    const { wallets } = useSelector(accountSelector);
    const [loading,
        setLoading] = useState(true);
    const token = localStorage.getItem('token');

    useEffect(() => {
        dispatch(getWallets({
            token,
            id,
            success: () => setLoading(false)
        }))
    }, [id]);


    const handlePageChange = (pageNumber) => {
        pageChange(
             () => setLoading(true),
            dispatch(getWallets({
                token,
                id,
                success: () => setLoading(false),
                page: pageNumber
            }))
        );
    }

    return (
        <div className="">
            <div className="">
                <div className="card__custom mt-4">
                    <h2 className="sectionText__big text__light mb-4">Coin Network</h2>

                    <div className="hasBorder__top py-4">
                        {
                            loading
                                ?
                                <div className="py-5 center">
                                    <Spinner dark/>
                                </div>
                                :
                                <>
                                    <table class="table fancy">
                                        <thead className='sectionText'>
                                            <th scope="col" className='vAlign'>#</th>
                                            <th scope="col" className='vAlign'>Network</th>
                                            <th scope="col" className='vAlign'>Symbol</th>
                                            <th scope="col" className='vAlign'>Name</th>
                                            <th scope="col" className='vAlign'>Token</th>
                                            <th scope="col" className='vAlign'>Status</th>
                                            <th scope="col" className='vAlign'>Copy</th>
                                            <th></th>
                                        </thead>
                                        <tbody>
                                            { (wallets.results || []).map((wallet, index) => {
                                                return <tr className='sectionText  vAlign'>
                                                    <td>{ index + 1 }</td>
                                                    <td>
                                                        { wallet.coin.network.chain }
                                                    </td>
                                                    <td>
                                                        <div className="logo__imgHolder">
                                                            <img src={ wallet.coin.logo } className='w-100' alt='' /> { wallet.coin.symbol }
                                                        </div>
                                                    </td>
                                                    <td>{ wallet?.coin?.network?.name }</td>
                                                    <td>False</td>
                                                    <td>
                                                        <span className="tag active">Active</span>
                                                    </td>
                                                    <td>
                                                        <span className="clickable"><i class="bi bi-clipboard text__gray mx-2"></i> Copy</span>
                                                    </td>
                                                    <td>
                                                        <span className="clickable"><i class="bi bi-slash-circle-fill text__gray mx-2"></i> Liquidity address</span>
                                                    </td>
                                                </tr>
                                            }) }

                                        </tbody>
                                    </table>
                                    <CustomPaginate totalPages={ wallets?.totalPages } activePage={ wallets?.page } handlePageChange={ (pageNumber) => handlePageChange(pageNumber) } />
                                </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
