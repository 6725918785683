import React from 'react'
import { Modal } from 'react-bootstrap';
import { TabList, Tab, Tabs, TabPanel, } from 'react-tabs';
import Dropdown from '../reusables/dropdown';
import InputField from '../reusables/input';
import Switcher from '../reusables/switcher';

function CreateModal({ show, handleClose }) {
    return (
        <Modal show={ show } onHide={ handleClose } className='modal__custom' centered>
            <div className="py-4 hasBorder__bottom">
                <p className="sectionText__big text__light vAlign">Create Coin / Asset</p>
                <i className="rAlign bi bi-x-lg sectionText__big icon__close clickable" onClick={ handleClose }></i>
            </div>

            <div className="container__custom mt-3">
                <Tabs>
                    <TabList className="tab__custom p-0">
                        <Tab>Parent Coin Network</Tab>
                        <Tab>Sub Token</Tab>
                    </TabList>

                    <TabPanel>
                        <form action="">
                            <div className="row">
                                <div className="col-md-10 my-2">
                                    <InputField label='Name' value="ETH" disabled />
                                </div>
                                <div className="col-md-2 my-2 alignOnly">
                                    <div className="m-auto">
                                        <p className="sectionText text__light mb-2">Symbol</p>
                                        <div className="asset__logo">
                                            <img src="/eth.png" className='w-100' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="py-3">
                                <p className="sectionText mb-1">Network</p>
                                <Dropdown value="ERC20">
                                    {
                                        ["ERC20", "ERC20"].map(option => (
                                            <div className="alignOnly option">
                                                <div className="radio mx-2 filled"></div>
                                                <p className="sectionText__mid mb-0">{ option }</p>
                                            </div>
                                        ))
                                    }
                                </Dropdown>
                            </div>

                            <div className="py-3">
                                <p className="sectionText mb-1">Is Token</p>
                                <Switcher />
                            </div>

                            <div className="center py-2">
                                <button className="btn__default sectionText">CREATE</button>
                            </div>

                        </form>
                    </TabPanel>

                    <TabPanel>
                        <form action="">
                            <div className="row">
                                <div className="col-md-10 my-2">
                                    <InputField label='Name' value="ETH" disabled />
                                </div>
                                <div className="col-md-2 my-2 alignOnly">
                                    <div className="m-auto">
                                        <p className="sectionText text__light mb-2">Symbol</p>
                                        <div className="asset__logo">
                                            <img src="/eth.png" className='w-100' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="py-3">
                                <p className="sectionText mb-1">Network</p>
                                <Dropdown value="ERC20">
                                    {
                                        ["ERC20", "ERC20"].map(option => (
                                            <div className="alignOnly option">
                                                <div className="radio mx-2 filled"></div>
                                                <p className="sectionText__mid mb-0">{ option }</p>
                                            </div>
                                        ))
                                    }
                                </Dropdown>
                            </div>

                            <div className="py-3">
                                <p className="sectionText mb-1">Is Token</p>
                                <Switcher />
                            </div>

                            <div className="">
                                <InputField textarea label="Smart Contract" placeholder="Enter Smart Contract" />
                            </div>

                            <div className="center py-2">
                                <button className="btn__default sectionText">CREATE</button>
                            </div>

                        </form>
                    </TabPanel>
                </Tabs>
            </div>

        </Modal>
    )
}

export default CreateModal


{/* <div className="vAlign py-3">
    <p className="sectionText__mid">Are you sure to set active BTC Coin</p>

    <div className="center sectionText__mid pt-3">
        <button className="btn__danger mx-2">Cancel</button>
        <button className="btn__default mx-2">Yes</button>
    </div>
</div> */}