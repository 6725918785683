import React, { useEffect, useState } from 'react'
import AccountDetail from '../account/accountDetail';
import { Tabs, TabPanel, TabList, Tab } from 'react-tabs';
import AccountTransactions from '../account/accountTransactions';
import { useDispatch, useSelector } from 'react-redux';
import { deactivateAccount } from '../../redux/slices/accountSlice';
import Spinner from '../reusables/spinner';
import { getUserData, userSelector } from '../../redux/slices/userSlice';
import AccountWallet from "../account/accountWallets"

function UserDetails({ prevSlide, selectedUser }) {

    const [index, setIndex] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [isDeactivated, setIsDeactivated] = useState(false)
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();

    const { userData } = useSelector(userSelector);


    // console.log({ selectedUser, userData })
    useEffect(() => {
        dispatch(getUserData({
            token,
            id: selectedUser?._id,
            success: () => setLoading(false)
        }))
        setIsDeactivated(userData?.user?.isDeactivated)
    }, [selectedUser?._id]);


    const handleSuccess = () => {
        setLoading(false);
    }

    const deactivate = () => {
        setLoading(true);
        dispatch(deactivateAccount({ token, success: () => handleSuccess, id: selectedUser?._id, deactivate: "deactivate" }))
        setLoading(false)
        setIsDeactivated(true)
    }

    const reactivate = () => {
        setLoading(true);
        dispatch(deactivateAccount({ token, success: () => handleSuccess, id: selectedUser?._id, deactivate: "reactivate" }))
        setLoading(false)
        setIsDeactivated(false)
    }

    return (
        <div className="">
            <Tabs selectedIndex={index} onSelect={(index) => setIndex(index)}>
                <div className="row mb-3">
                    <div className="col-lg-7 alignOnly my-3">
                        <i class="bi bi-chevron-left text__gray sectionHead__mid clickable" onClick={prevSlide}></i>

                        <TabList className='tab__button alignOnly px-2 mb-0'>
                            <Tab className='mx-2'>
                                Detail
                            </Tab>
                            <Tab className='mx-2'>
                                Transactions
                            </Tab>
                            <Tab className='mx-2'>
                                Wallets
                            </Tab>
                        </TabList>
                    </div>

                    <div className="col-lg-5 my-3">
                        <button className="btn__transparent mx-1" onClick={deactivate} disabled={isLoading} hidden={isDeactivated}>
                            {
                                isLoading ?
                                    <div className="my-1">
                                        <Spinner dark />
                                    </div>
                                    :
                                    <>
                                        <i class="bi bi-power mx-2"></i>
                                        Turn Off Account
                                    </>
                            }
                        </button>
                        <button className="btn__transparent mx-1" onClick={reactivate} disabled={isLoading} hidden={!isDeactivated}>
                            {
                                isLoading ?
                                    <div className="my-1">
                                        <Spinner dark />
                                    </div>
                                    :
                                    <>
                                        <i class="bi bi-power mx-2"></i>
                                        Turn On Account
                                    </>
                            }
                        </button>

                        <button className="btn__transparent mx-1">
                            <i class="bi bi-envelope mx-2"></i>
                            Send Email
                        </button>


                    </div>
                </div>

                <TabPanel>
                    <AccountDetail id={selectedUser?._id} />
                </TabPanel>
                <TabPanel>
                    <AccountTransactions id={selectedUser?._id} />
                </TabPanel>
                <TabPanel>
                    <AccountWallet id={selectedUser?._id} />
                </TabPanel>
            </Tabs>


        </div>
    )
}

export default UserDetails
