import React from 'react'
import './index.css';

function Spinner({big, dark}) {
  return (
    <div className={`${big && 'big'} ${dark && 'dark'} spinner__holder center mx-1`}>
        <div className="spinner__thumb"></div>
    </div>
  )
}

export default Spinner