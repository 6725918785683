import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/slices/authSlice";
import { months } from "../../utils";

import InputField from "./input";

function Nav({ pageName, toggleSideBar, className }) {
  const [timePerioid, setTimePeriod] = useState("");

  const { user } = useSelector(authSelector);
  const [active, setActive] = useState(false);

  let currentDate = new Date();

  let date =
    currentDate.getDate() +
    " " +
    months[currentDate.getMonth()] +
    " " +
    currentDate.getFullYear();

  let time = currentDate.getHours() + ":" + currentDate.getMinutes();

  useEffect(() => {
    if (currentDate.getHours() >= 12) {
      setTimePeriod("PM");
    } else {
      setTimePeriod("AM");
    }
  }, [currentDate]);

  return (
    <div className={`${className} container__custom mobile__hide`}>
      <div className="nav spaceBetween mb-3 ">
        <i
          className="bi bi-grid-fill sectionHead mobile__icon"
          onClick={toggleSideBar}
        ></i>

        <div className="alignOnly my-2">
          <div className="logo">
            <img src="/images/logo.svg" className="w-100" alt="" />
          </div>

          <p className="mb-0 sectionHead__mid ms-3 mt-1 mobile__hide">
            {pageName}
          </p>
        </div>

        <div className="mx-3 search">
          <InputField placeholder="Search Company name / Email" />
        </div>

        <div className="alignOnly nav__links my-2">
          <div className="sectionText  mx-3">
            <p className="mb-0">Date:</p>
            <span className="text__bold">{date}</span>
          </div>

          <div className="sectionText mx-3">
            <p className="mb-0">Time:</p>
            <span className="text__bold">
              {time} {timePerioid}
            </span>
          </div>

          <div className="sectionText  mx-3">
            <p className="mb-0">Online time:</p>
            <span className="text__bold">00:26:08</span>
          </div>

          <div className="mx-3 alignOnly profile">
            <div>
              <p className="sectionText mb-0 text__bold">{user.name?.full}</p>
              <p className="sectionText mb-0">Administrator</p>
            </div>
            <div className="profile__img mx-1">
              <img src={user.displayPhoto} alt="" />
            </div>
          </div>
        </div>
        <i
          className="bi bi-three-dots sectionHead mobile__icon"
          onClick={() => setActive(true)}
        ></i>
      </div>
      {active && (
        <div className="backdrop" onClick={() => setActive(false)}></div>
      )}
      <div className={`mobile__nav ${active && "active"}`}>
        <div className="mx-3 profile">
          <div>
            <div className="profile__img">
              <img src={user.displayPhoto} alt="" />
            </div>
            <p className="sectionText__small mb-0 text__bold">
              {user.name?.full}
            </p>
            <p className="sectionText__small vAlign  mt-2 mb-0">
              Administrator
            </p>
          </div>
        </div>

        <div className="sectionText py-3">
          <p className="mb-0">Date:</p>
          <span className="text__bold">{date}</span>
        </div>

        <div className="sectionText py-3">
          <p className="mb-0">Time:</p>
          <span className="text__bold">
            {time} {timePerioid}
          </span>
        </div>

        <div className="sectionText  py-3">
          <p className="mb-0">Online time:</p>
          <span className="text__bold">00:26:08</span>
        </div>
      </div>
    </div>
  );
}

export default Nav;
