import React, { useEffect, useState } from "react";
import Dropdown from "../reusables/dropdown";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Line, Doughnut } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { getReports, reportSelector } from "../../redux/slices/reportSlice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const lineData = {
  labels,
  datasets: [
    {
      label: "Transactions",
      borderWidth: 3,
      data: [0, 1100, 1500, 1300, 900, 2000, 1800, 1800, 2000, 3300],
      fill: true,
      borderColor: "#0D1CB3",
      // backgroundColor: '#fff',
      tension: 0.4,
    },
  ],
};

export const DoughnutData = {
  labels: ["Aggressive", "Blue", "Yellow", "Green", "Purple"],
  datasets: [
    {
      label: "# of Votes",
      data: [20, 3, 10, 7, 3],
      backgroundColor: ["#7DDB85", "#FF9100", "#D33F3F", "#D05CF3", "#3EB9E5"],
      borderWidth: 3,
    },
  ],
};

function Home({ nextSlide, lastSlide }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { report } = useSelector(reportSelector);
  const token = localStorage.getItem("token");

  console.log(report);

  useEffect(() => {
    setLoading(true);
    dispatch(getReports({ token, success: () => setLoading(false) }));
  }, []);

  return (
    <div>
      {report && (
        <>
          <div className="row">
            {/* Transaction Report */}
            <div className="col-md-6 my-4">
              <div className="card__custom h-100">
                <div className="hasBorder__bottom pb-3 spaceBetween">
                  <p className="sectionText__mid mb-0">Transaction Report</p>
                  <div className="sectionText__mid" onClick={nextSlide}>
                    <span className="text__primary text__light clickable">
                      Breakdown
                    </span>
                    <i className="bi bi-chevron-right mx-2"></i>
                  </div>
                </div>

                <div className="mt-4">
                  <div className="row">
                    <div className="col-md-4 my-2">
                      <p className="sectionText__small mb-1">Total</p>
                      <p className="sectionText mb-0 text__bold">
                        £ {report?.transaction?.total}
                      </p>
                    </div>
                    <div className="col-md-4 my-2">
                      <p className="sectionText__small mb-1">Average</p>
                      <p className="sectionText mb-0 text__bold">
                        £ {report?.transaction?.average}
                      </p>
                    </div>
                    <div className="col-md-4 my-2">
                      <p className="sectionText__small mb-1">
                        Estimated Profits
                      </p>
                      <p className="sectionText mb-0 text__bold">
                        £ {report?.transaction?.profit}
                      </p>
                    </div>
                  </div>

                  <div className="my-3">
                    <Line data={lineData} options={options} />
                  </div>

                  <div className="spaceBetween mt-3">
                    <div className="">
                      <Dropdown value="24 H" />
                    </div>

                    <div className="sectionText spaceBetween">
                      <p className="mb-0 mx-3 text__light">
                        High:{" "}
                        <span className="text__bold mx-2">
                          £ {report?.transaction?.high}
                        </span>
                      </p>
                      <p className="mb-0 mx-3 text__light">
                        Low:{" "}
                        <span className="text__bold mx-2">
                          £ {report?.transaction?.low}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Members Report */}
            <div className="col-md-6 my-4">
              <div className="card__custom">
                <div className="hasBorder__bottom pb-3 spaceBetween">
                  <p className="sectionText__mid mb-0">Members Report</p>
                </div>

                <div className="chart__holder">
                  <Doughnut data={DoughnutData} options={options} />
                </div>

                <div className="mt-4 spaceBetween">
                  <div className="sectionText__mid">
                    <p className=" mb-0">Total Members</p>
                    <p className="text__bold mb-0">{report?.member?.total}</p>
                  </div>

                  <div className="">
                    <Dropdown value="6 Months" borderOnly />
                  </div>
                </div>
              </div>
            </div>

            {/* Demographics Report */}
            <div className="col-md-6 my-4">
              <div className="card__custom">
                <div className="hasBorder__bottom pb-3 spaceBetween">
                  <p className="sectionText__mid mb-0"> Demographics Report</p>
                  <div
                    className="sectionText__mid clickable"
                    onClick={lastSlide}
                  >
                    <span className="text__primary text__light">Advanced</span>
                    <i className="bi bi-chevron-right mx-2"></i>
                  </div>
                </div>

                <div className="mt-4">
                  <div className="field__colored blue my-4 sectionText__mid">
                    <div className="field__label">
                      <div className="">
                        <i class="bi bi-person-fill"></i>
                        <span className="mx-3">Total Members</span>
                      </div>

                      <p className="mb-0">(100%)</p>
                    </div>
                    <div className="field__value">
                      <p className="mb-0 text__white">
                        {report?.member?.total}
                      </p>
                    </div>
                  </div>

                  <div className="field__colored pink my-4 sectionText__mid">
                    <div className="field__label">
                      <div className="">
                        <i class="bi bi-person-fill"></i>
                        <span className="mx-3">Men</span>
                      </div>

                      <p className="mb-0">
                        (
                        {parseInt(
                          (report?.member?.gender?.men /
                            report?.member?.total || 0) * 100
                        ).toFixed(0)}
                        %)
                      </p>
                    </div>
                    <div className="field__value">
                      <p className="mb-0 text__white">
                        {report?.member?.gender?.men}
                      </p>
                    </div>
                  </div>

                  <div className="field__colored green my-4 sectionText__mid">
                    <div className="field__label">
                      <div className="">
                        <i class="bi bi-person-fill"></i>
                        <span className="mx-3">Women</span>
                      </div>

                      <p className="mb-0">
                        (
                        {parseInt(
                          (report?.member?.gender?.female /
                            report?.member?.total || 0) * 100
                        ).toFixed(0)}
                        %)
                      </p>
                    </div>
                    <div className="field__value">
                      <p className="mb-0 text__white">
                        {report?.member?.gender?.female}
                      </p>
                    </div>
                  </div>

                  <div className="field__colored orange my-4 sectionText__mid">
                    <div className="field__label">
                      <div className="">
                        <i class="bi bi-person-fill"></i>
                        <span className="mx-3">18 - 25</span>
                      </div>

                      <p className="mb-0">
                        (
                        {parseInt(
                          (report?.member?.eigteenToTwenty /
                            report?.member?.total || 0) * 100
                        ).toFixed(0)}
                        %)
                      </p>
                    </div>
                    <div className="field__value">
                      <p className="mb-0 text__white">
                        {report?.member?.eigteenToTwenty}
                      </p>
                    </div>
                  </div>

                  <div className="field__colored red my-4 sectionText__mid">
                    <div className="field__label">
                      <div className="">
                        <i class="bi bi-person-fill"></i>
                        <span className="mx-3">United States</span>
                      </div>

                      <p className="mb-0">
                        (
                        {parseInt(
                          (report?.member?.us / report?.member?.total || 0) *
                            100
                        ).toFixed(0)}
                        %)
                      </p>
                    </div>
                    <div className="field__value">
                      <p className="mb-0 text__white">{report?.member?.us}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Account Type Report */}
            <div className="col-md-6 my-4">
              <div className="card__custom h-100">
                <div className="hasBorder__bottom pb-3 spaceBetween">
                  <p className="sectionText__mid mb-0">Account Type Report</p>
                </div>

                <div className="">
                  <div className="field__colored black my-4 sectionText__mid">
                    <div className="field__label">
                      <div className="">
                        <i class="bi bi-person-fill"></i>
                        <span className="mx-3">Personal Account</span>
                      </div>

                      <p className="mb-0">
                        (
                        {report?.member?.accountTypes?.personal /
                          report?.member?.total || 0 * 100}
                        %)
                      </p>
                    </div>
                    <div className="field__value">
                      <p className="mb-0 text__white">
                        {report?.member?.accountTypes?.personal}
                      </p>
                    </div>
                  </div>

                  <div className="field__colored orange my-4 sectionText__mid">
                    <div className="field__label">
                      <div className="">
                        <i class="bi bi-person-fill"></i>
                        <span className="mx-3">Gold Account</span>
                      </div>

                      <p className="mb-0">
                        (
                        {report?.member?.accountTypes?.gold /
                          report?.member?.total || 0 * 100}
                        %)
                      </p>
                    </div>
                    <div className="field__value">
                      <p className="mb-0 text__white">
                        {report?.member?.accountTypes?.gold}
                      </p>
                    </div>
                  </div>

                  <div className="field__colored red my-4 sectionText__mid">
                    <div className="field__label">
                      <div className="">
                        <i class="bi bi-person-fill"></i>
                        <span className="mx-3">Business Account</span>
                      </div>

                      <p className="mb-0">
                        (
                        {report?.member?.accountTypes?.business /
                          report?.member?.total || 0 * 100}
                        %)
                      </p>
                    </div>
                    <div className="field__value">
                      <p className="mb-0 text__white">
                        {report?.member?.accountTypes?.business}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Home;
