import React, { useState} from 'react'
import Nav from '../reusables/nav'
import CreateModal from '../wallet/createModal'
//import AssetAddresses from '../wallet/assetAddresses'

function Wallet() {
    const [modal, setModal] = useState(false);
    return (
        <div className="container__custom">
            <Nav pageName="Wallet" />
            <div className="">
                <div className="rAlign">
                    <button className='btn__default sectionText__mid' onClick={ () => setModal(true)}>Create</button>
                </div>

                <div className="card__custom mt-4">
                    <h2 className="sectionText__big text__light mb-4">Coin Network</h2>

                    <div className="hasBorder__top py-4">
                        <table class="table fancy">
                            <thead className='sectionText'>
                                <th scope="col" className='vAlign'>#</th>
                                <th scope="col" className='vAlign'>Network</th>
                                <th scope="col" className='vAlign'>Symbol</th>
                                <th scope="col" className='vAlign'>Name</th>
                                <th scope="col" className='vAlign'>Token</th>
                                <th scope="col" className='vAlign'>Status</th>
                                <th scope="col" className='vAlign'>Settings</th>
                                <th></th>
                            </thead>
                            <tbody>

                                <tr className='sectionText  vAlign'>
                                    <td>1</td>
                                    <td>
                                        ERC20
                                    </td>
                                    <td>
                                        <div className="logo__imgHolder">
                                            <img src='/eth.png' className='w-100' alt='' />
                                        </div>
                                    </td>
                                    <td>Ethereum</td>
                                    <td>False</td>
                                    <td>
                                        <span className="tag active">Active</span>
                                    </td>
                                    <td>
                                        <span className="clickable"><i class="bi bi-x-circle-fill text__gray mx-2"></i> Delete</span>
                                    </td>
                                    <td>
                                        <span className="clickable"><i class="bi bi-slash-circle-fill text__gray mx-2"></i> Liquidity address</span>
                                    </td>
                                </tr>
                                <tr className='sectionText  vAlign'>
                                    <td>2</td>
                                    <td>
                                        Bitcoin
                                    </td>
                                    <td>
                                        <div className="logo__imgHolder">
                                            <img src='/eth.png' className='w-100' alt='' />
                                        </div>
                                    </td>
                                    <td>BTC</td>
                                    <td>False</td>
                                    <td>
                                        <span className="tag inactive">Inactive</span>
                                    </td>
                                    <td>
                                        <span className="clickable"><i class="bi bi-x-circle-fill text__gray mx-2"></i> Delete</span>
                                    </td>
                                    <td>
                                        <span className="clickable"><i class="bi bi-slash-circle-fill text__gray mx-2"></i> Liquidity address</span>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <CreateModal show={ modal } handleClose={ () => setModal(false)}/>
        </div>
    )
}

export default Wallet
