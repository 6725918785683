import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { postNotifications } from "../../redux/slices/accountSlice";
import InputField from "../reusables/input";

function NotiForm({ name }) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const [data, setState] = useState({
    title: "",
    body: "",
  });

  const handleChange = (e) => {
    setState({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(postNotifications({ token, data }));
  };

  return (
    <div>
      <p className="sectionText__big text__light hasBorder__bottom">{name}</p>

      <form className="mt-3" onSubmit={handleSubmit}>
        <InputField
          label="Title"
          className="my-2"
          value={data.title}
          name="title"
          handleChange={handleChange}
        />

        <InputField
          textarea
          label="Description"
          value={data.body}
          name="body"
          handleChange={handleChange}
        />

        <div className="my-2">
          <p className="sectionText__mid text__light mb-1">Image</p>
          <div className="field__holder file-upload fit">
            <input type="file" />
            <div className="field__title">
              <p className="sectionText__mid mb-0">Choose File</p>
            </div>
            <div className="field__info">
              <p className="mb-0 sectionText__mid">Click to upload</p>
            </div>
          </div>
        </div>

        <div className="center mt-3">
          <button className="btn__default sectionText">Publish {name}</button>
        </div>
      </form>
    </div>
  );
}

export default NotiForm;
