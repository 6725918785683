import React, { useState } from "react";
import InputField from "../components/reusables/input";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../styles/auth.css";
import { login } from "../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import Spinner from "../components/reusables/spinner";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const [isLoading, setLoading] = useState(false);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const success = () => {
    navigate("/dashboard");
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(login({ state, success, error: () => setLoading(false) }));
  };

  return (
    <div className="auth__body center">
      <div className="auth__card">
        <div className="auth__card-head">
          <div className="imgHolder center">
            <img src="/assets/brand/logo_white2.svg" className="w-100" alt="" />
          </div>
        </div>
        <div className="col-lg-10 mx-auto my-4">
          <form onSubmit={handleSubmit}>
            <h2 className="sectionHead__mid pb-3">Login Admin</h2>

            <InputField
              placeholder="Email"
              name="email"
              value={state.email}
              handleChange={handleChange}
            />

            <InputField
              placeholder="Password"
              name="password"
              value={state.password}
              handleChange={handleChange}
              type="password"
            />

            <p className="sectionText rAlign py-2">
              <Link to="/forgot-password">
                <span className="text__primary">Forgot Password?</span>
              </Link>
            </p>

            <div className="py-4">
              <button
                className="btn__default sectionText w-100 center"
                disabled={isLoading}
              >
                {isLoading ? <Spinner /> : "Login"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
