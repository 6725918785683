import React from 'react'

function  ExchangeList () {
  return (
    <div>

          <div className="mt-4">
              <div className="card__custom">
                  <div className="spaceBetween mb-4">
                      <h2 className="sectionText__big">Exchange List</h2>
                  </div>

                  <div className="pt-4 hasBorder__top">
                      <table class="table transactions">
                          <thead className='sectionText__mid'>
                              <tr>
                                  <th scope="col">Transaction ID</th>
                                  <th scope="col">Today</th>
                                  <th scope="col">Pair</th>
                                  <th scope="col">Amount 1</th>
                                  <th scope="col">Amount 2</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Profits</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr className='sectionText text__light'>
                                  <td>jUhs7-65vxC-0u7ds-rrE2m</td>
                                  <td>
                                      Just Now
                                  </td>
                                  <td>MOR/GBP</td>
                                  <td>0.25000 MOR</td>
                                  <td>£ 2456,00</td>
                                  <td>
                                      <span className="text__mustard">Processing</span>
                                  </td>
                                  <td>
                                      <span className="text__mustard">£ 23,00</span>
                                  </td>
                              </tr>
                              <tr className='sectionText text__light'>
                                  <td>jUhs7-65vxC-0u7ds-rrE2m</td>
                                  <td>
                                      Just Now
                                  </td>
                                  <td>MOR/GBP</td>
                                  <td>0.25000 MOR</td>
                                  <td>£ 2456,00</td>
                                  <td>
                                      <span className="text__mustard">Processing</span>
                                  </td>
                                  <td>
                                      <span className="text__mustard">£ 6,00</span>
                                  </td>
                              </tr>

                          </tbody>
                      </table>

                  </div>
              </div>
          </div>

    </div>
  )
}

export default ExchangeList