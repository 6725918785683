import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getUserData, userSelector } from '../../redux/slices/userSlice';
import InputField from '../reusables/input';
import Spinner from '../reusables/spinner';

function AccountDetail({ id }) {

    const dispatch = useDispatch();
    const { userData } = useSelector(userSelector);
    const [loading,
        setLoading] = useState(true);
    const token = localStorage.getItem('token');

    useEffect(() => {
        dispatch(getUserData({
            token,
            id,
            success: () => setLoading(false)
        }))
    }, [id]);

    console.log(userData);

    return (
        <div>
            {loading
                ? <div className="center sectionHeight">
                    <Spinner dark />
                </div>
                :
                <div className="">
                    <div className="row">
                        <div className="col-lg-6 my-3">
                            <div className="card__custom">
                                <div className="spaceBetween">
                                    <div className="sectionText__big alignOnly">
                                        <i className="bi bi-wallet-fill text__primary"></i>

                                        <p className="mb-0 mx-4 text__light">Card</p>
                                    </div>

                                    <div className="">
                                        <div className="profile__img">
                                            <img src={userData?.user?.displayPhoto} alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-3">
                                    <div className="row">
                                        <div className="col-md-5 center">
                                            <img src="/images/card_visa.png" className='w-100' alt="" />
                                        </div>
                                        <div className="col-md-7 alignOnly">
                                            <div className="w-100">
                                                <div className="my-2">
                                                    <InputField disabled value={userData?.card?.name || userData?.user?.name?.full} label='Card Holder Name' inputClass="text__bold" />
                                                </div>

                                                <div className="my-2">
                                                    <InputField disabled value={userData?.card?.number} label="Card Number" inputClass="text__bold" />
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6 my-2">
                                                        <InputField disabled value={userData?.card?.expiry?.month || 0 + '/' + (userData?.card?.expiry?.year || 0)} label="Exp Date" inputClass="text__bold" />
                                                    </div>

                                                    <div className="col-md-6 my-2">
                                                        <InputField disabled value={userData?.card?.cvv || 0} label="CVV" inputClass="text__bold" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card__custom spaceBetween my-5">
                                <div className="text__gray">
                                    <p className="sectionText__big mb-0">Balance</p>
                                    <p className="sectionHead__mid mb-0">{userData?.card?.currency || "$"} {' '} {userData?.balance}</p>
                                </div>

                                <div className="">
                                    <InputField disabled value="$ 0" label='Monthly Fee' className='vAlign' inputClass="text__bold vAlign" />
                                </div>
                            </div>

                            <div className="card__custom  my-3">
                                <div className="sectionText__big alignOnly">
                                    <i className="bi bi-wallet-fill text__primary"></i>

                                    <p className="mb-0 mx-4 text__light">Additional Cardholders</p>
                                </div>

                                {/* <div className="mt-3">
                                    <InputField value="samantha@gmail.com" className="mt-2" disabled />
                                    <InputField value="Jasonalex@gmail.com" className="mt-2" disabled />
                                </div> */}

                                <div className="py-5 center">
                                    <p className="sectionText text__primary text__bold mb-0">No user found</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 my-3">
                            <div className="card__custom mb-4">
                                <div className="sectionText__big alignOnly mb-3">
                                    <i className="bi bi-wallet-fill text__primary"></i>

                                    <p className="mb-1 mx-4 sectionText__big text__light">Personal Info</p>
                                </div>

                                <div className="mt-5">
                                    <div className="field__holder my-3">
                                        <div className="field__title">
                                            <p className="sectionText__mid mb-0">Full Name</p>
                                        </div>
                                        <div className="field__info">
                                            <p className="mb-0 sectionText__mid">{userData?.user?.name?.full}</p>
                                        </div>
                                    </div>
                                    <div className="field__holder my-3">
                                        <div className="field__title">
                                            <p className="sectionText__mid mb-0">Account Type</p>
                                        </div>
                                        <div className="field__info">
                                            <p className="mb-0 sectionText__mid">{userData?.type} {' '} account</p>
                                        </div>
                                    </div>
                                    <div className="field__holder my-3">
                                        <div className="field__title">
                                            <p className="sectionText__mid mb-0">Role</p>
                                        </div>
                                        <div className="field__info">
                                            <p className="mb-0 sectionText__mid">{userData?.user?.role}</p>
                                        </div>
                                    </div>
                                    <div className="field__holder my-3">
                                        <div className="field__title">
                                            <p className="sectionText__mid mb-0">Email</p>
                                        </div>
                                        <div className="field__info">
                                            <p className="mb-0 sectionText__mid">{userData?.user?.email}</p>
                                        </div>
                                    </div>
                                    <div className="field__holder my-3">
                                        <div className="field__title">
                                            <p className="sectionText__mid mb-0">Phone Number</p>
                                        </div>
                                        <div className="field__info">
                                            <p className="mb-0 sectionText__mid">{userData?.user?.phoneNumber}</p>
                                        </div>
                                    </div>
                                    <div className="field__holder my-3">
                                        <div className="field__title">
                                            <p className="sectionText__mid mb-0">Address</p>
                                        </div>
                                        <div className="field__info">
                                            <p className="mb-0 sectionText__mid">{userData?.user?.address}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card__custom my-5">

                                <div className="sectionText__big alignOnly">
                                    <i className="bi bi-wallet-fill text__primary"></i>

                                    <p className="mb-1 text__light  mx-4">Document KYC</p>
                                </div>

                                <div className="mt-3">
                                    <InputField value={userData?.user?.documentType || "No Document Submitted Yet"} className="mt-2" disabled />
                                    {/* <InputField value="National Identity Card.jpg" className="mt-2" disabled />
                                    <InputField value="Drivers License.jpg" className="mt-2" disabled /> */}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default AccountDetail
