import React from 'react'
import Nav from '../reusables/nav';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import NotiForm from '../pushNotification/notiForm';
import NotiList from '../pushNotification/notiList';

function PushNotifcations() {
    return (
        <div className="container__custom">
            <Nav pageName="Push Notification" />
            <Tabs>
                <div className="">
                    <div className="">
                        <TabList className='tab__button alignOnly p-0 mb-0'>
                            <Tab className='mx-2'>
                                Notification
                            </Tab>
                            <Tab className='mx-2'>
                                Discount
                            </Tab>
                            <Tab className='mx-2'>
                                Pop Up Notification
                            </Tab>
                        </TabList>
                    </div>
                </div>

                <div className="mt-4">

                    <TabPanel>
                        <>
                            <div className="card__custom my-4">
                                <NotiForm name="Notification" />
                            </div>

                            <div className="my-3 card__custom">
                                <NotiList name='Notification'/>
                            </div>
                        </>
                    </TabPanel>

                    <TabPanel>
                        <>
                            <div className="card__custom my-4">
                                <NotiForm name="Discount" />
                            </div>

                            <div className="my-3 card__custom">
                                <NotiList name='Discount' />
                            </div>
                        </>
                    </TabPanel>

                    <TabPanel>
                        <>
                            <div className="card__custom my-4">
                                <NotiForm name="Pop up Notification" />
                            </div>

                            <div className="my-3 card__custom">
                                <NotiList name='Pop up Notification' />
                            </div>
                        </>
                    </TabPanel>

                </div>
            </Tabs>
        </div>

    )
}

export default PushNotifcations
