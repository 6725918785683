import React, { useState, useEffect } from 'react'
import '../styles/dashboard.css';
import AccountList from '../components/dashboard/accountList';
import Home from '../components/dashboard';
import Transactions from '../components/dashboard/transactions';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import KYC from '../components/dashboard/kyc';
import FeeSettings from '../components/dashboard/feeSettings';
import PushNotifications from '../components/dashboard/pushNotifications';
import Wallet from '../components/dashboard/wallet';
import Reports from '../components/dashboard/reports';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/slices/authSlice';
import Nav from '../components/reusables/nav';

function Dashboard() {

    const [index,
        setIndex] = useState(0);

    const [active, setActive] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = localStorage.getItem('token');

    const navLinks = [
        {
            icon: 'bi-house-door-fill',
            text: 'Dashboard'
        }, {
            icon: 'bi-person-fill',
            text: 'Account'
        }
        , {
            icon: 'bi-currency-exchange',
            text: 'Transactions'
        }
        , {
            icon: 'bi-person-vcard-fill',
            text: 'KYC Listing'
        }, {
            icon: 'bi-cash-coin',
            text: 'Fee Settings'
        }, {
            icon: 'bi-bell-fill',
            text: 'Push Notification'
        }, {
            icon: 'bi-file-text-fill',
            text: 'Reports'
        }
        , {
            icon: 'bi-wallet-fill',
            text: 'Wallet'
        }
    ]

    const success = () => {
        localStorage.clear();
        navigate("/")
    }

    const handleLogout = () => {
        dispatch(logout({ token, success }));
    }

    // useEffect(() => {
    //     if(!token){
    //         navigate("/")
    //     }
    // }, [token])

    return (
        <div className="">
            <div className='bg__blue-light lay__holder'>

                <Tabs selectedIndex={index} onSelect={(index) => setIndex(index)}>
                    <div className="">
                        {/* <Nav toggleSideBar={ () => setActive(!active) } className="desk__hide"/> */}
                        <div className={`sideBar ${active && 'active'}`}>
                            {
                                active && <div className="backdrop" onClick={() => setActive(false)}></div>
                            }
                            <TabList className='p-0 w-100'>
                                {navLinks.map((link, idx) => (
                                    <Tab className="my-4 text__white clickable link__holder" key={idx}>
                                        <> <i className={`mb-1 sectionText__mid ${link.icon} center`}></i>
                                            <p className="sectionText__small vAlign mb-0">{link.text}</p>
                                        </>
                                    </Tab>
                                ))}
                                <div className="my-4 text__white clickable link__holder" onClick={handleLogout}>
                                    <> <i className={`mb-1 sectionText__mid bi-box-arrow-left center`}></i>
                                        <p className="sectionText__small vAlign mb-0">Logout</p>
                                    </>
                                </div>
                            </TabList>
                        </div>

                        <div className="lay__content">
                            <TabPanel>
                                <Home />
                            </TabPanel>
                            <TabPanel>
                                <AccountList />
                            </TabPanel>
                            <TabPanel>
                                <Transactions />
                            </TabPanel>
                            <TabPanel>
                                <KYC />
                            </TabPanel>
                            <TabPanel>
                                <FeeSettings />
                            </TabPanel>
                            <TabPanel>
                                <PushNotifications />
                            </TabPanel>
                            <TabPanel>
                                <Reports />
                            </TabPanel>
                            <TabPanel>
                                <Wallet />
                            </TabPanel>
                        </div>
                    </div>
                </Tabs>
            </div>
        </div>
    )
}

export default Dashboard