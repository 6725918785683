import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { toast } from 'react-toastify';


export const getAccounts = createAsyncThunk('account/getAccounts',
    async ({ token, success, page }, thunkAPI) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/account/?page=${page || 1}&limit=${10}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(response)
            success();

            return response.data?.data
        }
        catch (err) {
            toast.error(err.response?.data?.msg);
            console.log(err.response?.data?.msg);
        }
    })

export const createAccount = createAsyncThunk('account/createAccount',
    async ({ state, token, success, error }, thunkAPI) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/register`, state, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });

            toast.success(response.data?.msg);
            success();

            return response.data?.data
        }
        catch (err) {
            toast.error(err.response?.data?.msg);
            error();
        }
    });

export const deactivateAccount = createAsyncThunk('account/deactivateAccount',
    async ({ token, id, success }, thunkAPI) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/account/deactivate/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });

            toast.success(response.data.msg);
            success();

            return response.data?.data
        }
        catch (err) {
            success();
            toast.error(err.response?.data?.msg);
        }
    })

export const deleteAccount = createAsyncThunk('account/deleteAccount', 
    async ({ token, id, success }, thunkAPI) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/accounts/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });

            toast.success('Account Deleted successfully');
            success();

            return response.data?.data
        }
        catch (err) {
            success();
            toast.error(err.response?.data?.msg);
        }
    })

export const updateAccount = createAsyncThunk('account/updateAccount',
    async ({ token, data, id, success }, thunkAPI) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/admin/account/${id}`, data, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });

            toast.success('Account Updated successfully');
            success();

            return response.data?.data
        }
        catch (err) {
            toast.error(err.response?.data?.msg);
        }
    })

export const getTransactions = createAsyncThunk('account/getTransactions',
    async ({ token, success, id, page }, thunkAPI) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/account/transactions/${id}?page=${page || 1}&limit=${10}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(response)
            success();

            return response.data?.data
        }
        catch (err) {
            toast.error(err.response?.data?.msg);
            console.log(err.response?.data?.msg);
        }
    })



export const getWallets = createAsyncThunk('account/getWallets',
    async ({ token, success, id, page }, thunkAPI) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/account/wallets/${id || ""}?page=${page || 1}&limit=${10}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(response)
            success();

            return response.data?.data
        }
        catch (err) {
            toast.error(err.response?.data?.msg);
            console.log(err.response?.data?.msg);
        }
    })


export const getKyc = createAsyncThunk('account/getKyc',
    async ({ token, success, page }, thunkAPI) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/kyc?page=${page || 1}&limit=${10}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(response)
            success();

            return response.data?.data
        }
        catch (err) {
            toast.error(err.response?.data?.msg);
            console.log(err.response?.data?.msg);
        }
    })

export const postNotifications = createAsyncThunk('account/postNotifications',
    async ({ token, data }, thunkAPI) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin/sendNotifications`, data, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(response);
            toast.success(response.data?.msg);
            return response.data?.data
        }
        catch (err) {
            toast.error(err.response?.data?.message);
        }
    })

export const getNotifications = createAsyncThunk('account/getNotifications',
    async ({ token }, thunkAPI) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/broadcasts`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(response);
            return response.data?.data
        }
        catch (err) {
            toast.error(err.response?.data?.message);
        }
    })


const initialState = {
    accounts: [],
    transactions: [],
    kycs: [],
    wallets: [],
    broadcasts: {}
};

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {},
    extraReducers: {
        [getAccounts.fulfilled]: (state, { payload }) => {
            state.accounts = payload
        },
        [getTransactions.fulfilled]: (state, { payload }) => {
            state.transactions = payload
        },
        [getKyc.fulfilled]: (state, { payload }) => {
            state.kycs = payload
        },
        [getWallets.fulfilled]: (state, { payload }) => {
            state.wallets = payload
        },
        [createAccount.fulfilled]: (state, {payload}) => {
            let data = state.accounts;
            let updated = [
                ...data,
                payload
            ]
            state.accounts = updated;
        },
        [getNotifications.fulfilled]: (state, {payload}) => {
            state.notifications = payload
        }
    }
})

export const accountSelector = state => state.account
export default accountSlice.reducer