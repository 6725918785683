import React, { useEffect, useState } from "react";
import Nav from "../reusables/nav";
import { Modal, Carousel } from "react-bootstrap";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { getKyc, accountSelector } from "../../redux/slices/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import { pageChange } from "../../utils";
import CustomPaginate from "../reusables/customPaginate";
import Spinner from "../reusables/spinner";

function KYC() {
  const [active, setActive] = useState(false);

  const [index, setIndex] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  const { kycs } = useSelector(accountSelector);

  console.log({ kycs });
  useEffect(() => {
    setLoading(true);
    dispatch(
      getKyc({
        token,
        success: () => setLoading(false),
      })
    );
  }, []);

  const handlePageChange = (pageNumber) => {
    pageChange(
      () => setLoading(true),
      dispatch(
        getKyc({
          token,
          success: () => setLoading(false),
          page: pageNumber,
        })
      )
    );
  };

  const rejectionReasons = {
    1: [
      "Photo are blurry",
      "Invalid document",
      "Need more brightness",
      "Photos are cropped",
      "Edited photo",
    ],
    2: [
      "Document unreadable",
      "Unmatched document",
      "Photo is covered",
      "Photo is too close",
      "Photo is too far",
    ],
  };

  return (
    <div className="container__custom">
      <Nav pageName="KYC Listing" />
      <Tabs>
        <div className="spaceBetween">
          <div className="">
            <TabList className="tab__button alignOnly p-0 mb-0">
              <Tab className="mx-2">
                Waiting for Approval {"  "}
                <i className="bg__coke p-1">
                  <span className="text__danger sectionText px-1 d-inline-block">
                    {kycs?.rejected?.totalPages || 0}
                  </span>
                </i>
              </Tab>
              <Tab className="mx-2">
                Approved
                <i className="bg__coke p-1">
                  <span className="text__danger sectionText px-1 d-inline-block">
                    {kycs?.approved?.totalPages || 0}
                  </span>
                </i>
              </Tab>
            </TabList>
          </div>

          <div className="card__custom alignOnly px-5">
            <div className="sectionText mx-3">
              <p className="mb-0 text__light">Average approval time</p>
              <p className="text__bold mb-0">8 hours</p>
            </div>
            <div className="sectionText mx-3">
              <p className="mb-0 text__light">Recommended approval time</p>
              <p className="text__bold mb-0">12 hours</p>
            </div>
          </div>
        </div>

        <TabPanel>
          <div className="mt-4">
            <div className="card__custom">
              <div className="center mb-4">
                <div className="mx-3 alignOnly">
                  <div className="label black"></div>
                  <p className="sectionText mb-0 mx-3">Just waiting</p>
                </div>

                <div className="mx-3 alignOnly">
                  <div className="label mustard"></div>
                  <p className="sectionText mb-0 mx-3">Medium waiting time</p>
                </div>

                <div className="mx-3 alignOnly">
                  <div className="label danger"></div>
                  <p className="sectionText mb-0 mx-3">Critical waiting time</p>
                </div>
              </div>

              <div className="pt-4 hasBorder__top">
                {isLoading ? (
                  <div className="center py-4">
                    <Spinner dark />
                  </div>
                ) : (
                  <>
                    <table class="table transactions">
                      <thead className="sectionText__mid">
                        <tr>
                          <th scope="col">Full Name</th>
                          <th scope="col">Register Date</th>
                          <th scope="col">Waiting Time</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone Number</th>
                          <th scope="col">KYC Documents</th>
                        </tr>
                      </thead>
                      <tbody>
                        {" "}
                        {(kycs?.rejected?.results || []).map((kyc) => {
                          return (
                            <tr className="sectionText text__light">
                              <td>{kyc?.user?.name?.full}</td>
                              <td>{kyc?.createdAt}</td>
                              <td>
                                <span className="text__mustard"> 05:31</span>
                              </td>
                              <td>{kyc?.user?.email}</td>
                              <td>{kyc?.number}</td>
                              <td className="vAlign">
                                <span
                                  className="text__primary text__bold clickable"
                                  onClick={() => setActive(true)}
                                >
                                  Review
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <CustomPaginate
                      totalPages={kycs?.rejected?.totalPages}
                      activePage={kycs?.rejected?.page}
                      handlePageChange={(pageNumber) =>
                        handlePageChange(pageNumber)
                      }
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="mt-4">
            <div className="card__custom">
              {/* <div className="center mb-4">
                            <div className="mx-3 alignOnly">
                                <div className='label black'></div>
                                <p className="sectionText mb-0 mx-3">Just waiting</p>
                            </div>

                            <div className="mx-3 alignOnly">
                                <div className='label mustard'></div>
                                <p className="sectionText mb-0 mx-3">Medium waiting time</p>
                            </div>

                            <div className="mx-3 alignOnly">
                                <div className='label danger'></div>
                                <p className="sectionText mb-0 mx-3">Critical waiting time</p>
                            </div>
                        </div> */}

              <div className="pt-4 hasBorder__top">
                {isLoading ? (
                  <div className="center py-4">
                    <Spinner dark />
                  </div>
                ) : (
                  <>
                    <table class="table transactions">
                      <thead className="sectionText__mid">
                        <tr>
                          <th scope="col">Full Name</th>
                          <th scope="col">Register Date</th>
                          <th scope="col">Waiting Time</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone Number</th>
                          <th scope="col">KYC Documents</th>
                        </tr>
                      </thead>
                      <tbody>
                        {" "}
                        {(kycs?.approved?.results || []).map((kyc) => {
                          return (
                            <tr className="sectionText text__light">
                              <td>{kyc?.user?.name?.full}</td>
                              <td>{kyc?.createdAt}</td>
                              <td>
                                <span className="text__mustard"> 05:31</span>
                              </td>
                              <td>{kyc?.user?.email}</td>
                              <td>{kyc?.number}</td>
                              <td className="vAlign">
                                <span
                                  className="text__primary text__bold clickable"
                                  onClick={() => setActive(true)}
                                >
                                  Review
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <CustomPaginate
                      totalPages={kycs?.approved?.totalPages}
                      activePage={kycs?.approved?.page}
                      handlePageChange={(pageNumber) =>
                        handlePageChange(pageNumber)
                      }
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>

      <Modal
        show={active}
        onHide={() => setActive(false)}
        centered
        className="modal__custom"
      >
        <Carousel activeIndex={index} controls={false} indicators={false}>
          <Carousel.Item>
            <div className="">
              <div className="text__light py-4 center">
                <p className="sectionText__big vAlign mb-0">
                  KYC Documents Approval
                </p>

                <i
                  className="rAlign bi bi-x-lg sectionText__big icon__close clickable"
                  onClick={() => setActive(false)}
                ></i>
              </div>

              <div className="container__custom hasBorder__top">
                <div className="row py-4">
                  <div className="col-md-4 my-3">
                    <div className="doc__preview"></div>
                    <div className="spaceBetween sectionText pt-3">
                      <p className="mb-0 ">Photo ID</p>
                      <p className="mb-0 text__primary">metadata</p>
                    </div>
                  </div>
                  <div className="col-md-4 my-3">
                    <div className="doc__preview"></div>
                    <div className="spaceBetween sectionText pt-3">
                      <p className="mb-0 ">National Identity Card</p>
                      <p className="mb-0 text__primary">metadata</p>
                    </div>
                  </div>
                  <div className="col-md-4 my-3">
                    <div className="doc__preview"></div>
                    <div className="spaceBetween sectionText pt-3">
                      <p className="mb-0 ">Drivers License</p>
                      <p className="mb-0 text__primary">metadata</p>
                    </div>
                  </div>
                </div>

                <div className="center pb-4">
                  <button
                    className="btn__danger sectionText  mx-2"
                    onClick={() => setIndex(1)}
                  >
                    Reject
                  </button>
                  <button className="btn__default  sectionText mx-2">
                    Approve
                  </button>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="">
              <div className="text__light py-4 spaceBetween sectionText__big container__custom">
                <i
                  className="bi bi-chevron-left clickable"
                  onClick={() => setIndex(0)}
                ></i>

                <p className="vAlign mb-0">Rejection Reason</p>

                <i
                  className="rAlign bi bi-x-lg  clickable"
                  onClick={() => setActive(false)}
                ></i>
              </div>

              <div className="container__custom hasBorder__top">
                <div className="row px-3">
                  <div className="col-md-6">
                    {rejectionReasons[1].map((reason) => (
                      <div className="my-3 alignOnly text__light">
                        <input type="checkbox" className="clickable" />
                        <p className="sectionText__mid mb-0 px-4">{reason}</p>
                      </div>
                    ))}
                  </div>

                  <div className="col-md-6">
                    {rejectionReasons[2].map((reason) => (
                      <div className="my-3 alignOnly text__light">
                        <input type="checkbox" />
                        <p className="sectionText__mid mb-0 px-4">{reason}</p>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="center pt-5 pb-3">
                  <button className="btn__danger sectionText">Reject</button>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </Modal>
    </div>
  );
}

export default KYC;
