import React, { useState, useEffect } from 'react'
import Nav from '../reusables/nav'
import InputField from '../reusables/input'
import { Carousel } from 'react-bootstrap';
import UserDetails from './userDetails';
import { useDispatch, useSelector } from 'react-redux';
import { accountSelector, deactivateAccount, deleteAccount, getAccounts } from '../../redux/slices/accountSlice';
import Spinner from '../reusables/spinner';
import CreateAccountModal from '../reusables/createAccountModal';
import CustomPaginate from '../reusables/customPaginate';


function AccountList({ nextSlide, selectUser, createModal }) {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [actionLoading, setActionLoading] = useState({
        update: '',
        delete: '',
        deactivate: ''
    })
    const { accounts } = useSelector(accountSelector);
    const token = localStorage.getItem('token');

    console.log(accounts);

    useEffect(() => {
        setTimeout(() => setLoading(false), 5000)
        dispatch(getAccounts({ token, success: () => setLoading(false) }));
    }, []);

    const handleSelect = (data) => {
        console.log({ data })
        selectUser(data);
        nextSlide();
    }

    const handleDeactivate = (id) => {
        setActionLoading({
            ...actionLoading,
            deactivate: id
        });

        dispatch(deactivateAccount({
            token, id, success: setActionLoading({
                ...actionLoading,
                deactivate: null
            })
        }))
    }

    const handleUpdate = (user) => {
        selectUser(user);
        createModal();
    }

    const handleDelete = (id) => {
        setActionLoading({
            ...actionLoading,
            delete: id
        });

        dispatch(deleteAccount({
            token, id, success: () => {
                setActionLoading({
                    ...actionLoading,
                    delete: null
                });
            }
        }))
    }

    const handlePageChange = (pageNumber) => {
        console.log(pageNumber);
       setLoading(true)
        dispatch(getAccounts({ token, success: () => setLoading(false), page: pageNumber }));
    };

    return (
        <div className="">
            <button className="btn__default sectionText__mid my-3 center" onClick={ createModal }>
                <i className="bi bi-plus sectionText__mid mx-2"></i>
                Add User
            </button>

            <div className="card__custom">
                <div className="spaceBetween mb-4">
                    <div className="sectionText__big alignOnly">
                        <i className="bi bi-person-fill text__primary"></i>

                        <p className="mb-0 mx-3 sectionText__big">Account List</p>

                        <p className="sectionText mb-0">Click image to view more</p>
                    </div>

                    <div className="">
                        <InputField placeholder='Search' />
                    </div>
                </div>

                <div className="pt-4 hasBorder__top transactions">
                    {
                        loading
                            ?
                            <div className="center py-5">
                                <Spinner dark />
                            </div>
                            :
                            accounts?.results?.length >= 0
                                ?
                                <>
                                    <table class="table">
                                        <thead className='sectionText__mid'>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Image</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Account Type</th>
                                                <th scope="col">Registered</th>
                                                <th scope="col">Balance</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (accounts?.results || []).map(account => (
                                                    <tr className='sectionText text__light' key={account?._id}>
                                                        <td>{ accounts?.results?.indexOf(account) + 1 }</td>
                                                        <td>
                                                            <div className="profile__img clickable" onClick={ () => handleSelect(account) }>
                                                                <img src={ account?.user?.displayPhoto } alt="" />
                                                            </div>
                                                        </td>
                                                        <td>{ account?.user?.name?.full || account?.user?.name?.last }</td>
                                                        <td>{ account?.type }</td>
                                                        <td>{ account?.createdAt }</td>
                                                        <td>$ { Number(account?.balance?.$numberDecimal) }</td>
                                                        <td>
                                                            <div className="mt-1">
                                                                <button disabled={ actionLoading.update } className="table__actionBtn mx-2" onClick={ () => handleUpdate(account) }>
                                                                    { actionLoading.update
                                                                        ?
                                                                        <Spinner dark />
                                                                        :
                                                                        <i className="bi bi-pencil-square"></i>
                                                                    }
                                                                </button>

                                                                <button disabled={ actionLoading.deactivate } className="table__actionBtn mx-2" onClick={ () => handleDeactivate(account?._id) }>
                                                                    { actionLoading.deactivate == account?._id
                                                                        ?
                                                                        <Spinner dark />
                                                                        :
                                                                        <i className="bi bi-power"></i>
                                                                    }
                                                                </button>

                                                                <button className="table__actionBtn mx-2" onClick={ () => handleDelete(account?._id) }>
                                                                    { actionLoading.delete == account?._id
                                                                        ?
                                                                        <Spinner dark />
                                                                        :
                                                                        <i className="bi bi-trash3"></i>
                                                                    }
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    <CustomPaginate totalPages={accounts?.totalPages} activePage={accounts?.page} handlePageChange={(pageNumber) => handlePageChange(pageNumber)}/>
                                </>
                                :
                                <div className="center py-5">
                                    <p className="sectionText__big text__light text__primary mb-0">No data found</p>
                                </div>
                    }
                </div>
            </div>
        </div>
    )
}


const Account = () => {

    const [index, setIndex] = useState(0);
    const [selectedUser, setSelected] = useState(null);
    const [modal, setModal] = useState(false)

    return (
        <div className="container__custom">
            <Nav pageName='User' />
            <Carousel activeIndex={ index } controls={ false } indicators={ false }>
                <Carousel.Item>
                    <AccountList nextSlide={ () => setIndex(1) } selectUser={ (user) => setSelected(user) } createModal={ () => setModal(true) } />
                </Carousel.Item>
                <Carousel.Item>
                    <UserDetails prevSlide={ () => setIndex(0) } selectedUser={ selectedUser } />
                </Carousel.Item>
            </Carousel>
            <CreateAccountModal show={ modal } handleClose={ () => setModal(false) } />
        </div>
    )
}

export default Account
