import React, {useState} from 'react'
import {Modal} from 'react-bootstrap';
import {accountTypes} from '../../utils';
import Dropdown from '../reusables/dropdown';

function ExportModal({show, handleClose}) {
    
    let reportOptions = ["Transactions", "Transfers"];

    const [state,
        setState] = useState({
        report: reportOptions[0],
        type: accountTypes[0],
        duration: {
            from: {
                day: '01',
                month: "September",
                year: "2021"
            },
            to: {
                day: '01',
                month: "September",
                year: "2022"
            }
        }
    });

    return (
        <Modal show={show} onHide={handleClose} centered>
            <div className="hasBorder__bottom sectionText__mid text__light py-4">
                <p className="center mb-0">Export to XLS</p>
                <i
                    className="rAlign bi bi-x-lg sectionText__big icon__close clickable"
                    onClick={handleClose}></i>
            </div>

            <div className="container px-4 py-3">
                <form>
                    <div className="my-4">
                        <Dropdown
                            label="Report:"
                            options={state.reportOptions}
                            value={state.report}
                            long
                            onChange={item => setState({
                            ...state,
                            report: item
                        })}/>
                    </div>

                    <div className="my-4">
                        <Dropdown
                            label="Type:"
                            options={accountTypes}
                            value={state.type}
                            long
                            onChange={item => setState({
                            ...state,
                            type: item
                        })}/>
                    </div>

                    <div className="my-4">
                        <p className="sectionText mb-2  text__light">From</p>
                        <div className="alignOnly">
                            <Dropdown value={state.duration.from.day}/>
                            <Dropdown value={state.duration.from.month} className='mx-2'/>
                            <Dropdown value={state.duration.from.year}/>
                        </div>
                    </div>

                    <div className="my-4">
                        <p className="sectionText mb-2 text__light">To</p>
                        <div className="alignOnly">
                            <Dropdown value={state.duration.to.day}/>
                            <Dropdown value={state.duration.to.month} className='mx-2'/>
                            <Dropdown value={state.duration.to.year}/>
                        </div>
                    </div>

                    <div className="my-4">
                        <p className="sectionText mb-2">Destination:</p>
                        <p className="sectionText clickable">
                            C:\Users\Desktop\Public\Documen...
                            <i className='bi bi-file text__primary'></i>
                        </p>
                    </div>

                    <button className="my-3 btn__default w-100">Export</button>
                </form>
            </div>
        </Modal>
    )
}

export default ExportModal