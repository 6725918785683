import React, { useState } from 'react';
import Dropdown from '../reusables/dropdown';
import QuickReport from '../demographic/quickReport'
import ReportResult from '../demographic/reportResult';

function Demographic({ prevSlide }) {

    const [modals, setModals] = useState({
        report: false,
        result: false
    })

    const countryFilter = ['By Continent', 'By Country', 'By Timezone']

    const state = {
        total_members: '344,336',
        last_month: '54,601',
        new_members: '4,601'
    }

    const countryList = [
        {
            name: 'United States',
            tagColor: '#5592FC'
        },
        {
            name: 'Canada',
            tagColor: '#B0CB55'
        },
        {
            name: 'Mexico',
            tagColor: '#6DF2A6'
        }
    ]

    return (
        <div>
            <div className="row">
                <div className="col-md-8 my-2">
                    <div className="alignOnly">
                        <i className="bi bi-chevron-left sectionHead__mid clickable" onClick={ prevSlide }></i>

                        <Dropdown value={ countryFilter[0] } options={ countryFilter } filter />

                        <button className="btn__default mx-4" onClick={ () => setModals({
                            ...state,
                            report: true
                        }) }> Quick Report </button>
                    </div>
                </div>

                <div className="col-md-4 my-2">
                    <div className="spaceBetween">
                        <div className="mx-4">
                            <p className="mb-0 sectionText__small text__light">Total Members</p>
                            <p className="sectionHead__mid mb-0">{ state.total_members }</p>
                        </div>

                        <div className="mx-4">
                            <p className="mb-0 sectionText__small text__light">Last Month</p>
                            <p className="sectionHead__mid mb-0">{ state.last_month }</p>
                        </div>

                        <div className="mx-4">
                            <p className="mb-0 sectionText__small text__light">New Members</p>
                            <p className="sectionHead__mid mb-0">{ state.new_members }</p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="mt-3">
                <div className="row">
                    <div className="col-md-8">
                        <div className="chart__holder lg"></div>
                    </div>
                    <div className="col-md-4 my-2">
                        <div className="card__custom h-100">
                            <div className="search__input alignOnly">
                                <input type="text" />

                                <button className="icon__holder center text__bold">
                                    <i className="bi bi-search"></i>
                                </button>
                            </div>

                            <div className="countryList mt-4">
                                {
                                    countryList?.map(item => (
                                        <div className="option spaceBetween">
                                            <div className="alignOnly">
                                                <div className="radio" style={ { backgroundColor: item.tagColor } }></div>
                                                <p className="sectionText__mid text__bold mb-0 mx-2">{ item.name }</p>
                                                <p className="sectionText text__gray mb-0 mx-2">(117,125)</p>
                                            </div>

                                            <i className="bi bi-chevron-right sectionText clickable"></i>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row my-4">
                    <div className="col-md-5">
                        <div className="card__custom">
                            <h3 className="sectionHead__mid text__bold">GENDER</h3>
                        </div>
                    </div>

                    <div className="col-md-7">
                        <div className="card__custom">
                            <h3 className="sectionHead__mid text__bold">AGE</h3>
                        </div>
                    </div>
                </div>
            </div>
            <QuickReport show={ modals.report } handleClose={ () => setModals({
                ...modals,
                report: false
            }) }
                generateReport={ () => setModals({
                    ...modals,
                    result: true,
                    report: false
                }) }
            />

            <ReportResult show={ modals.result } handleClose={ () => setModals({
                ...modals,
                result: false
            }) } />
        </div>
    )
}

export default Demographic