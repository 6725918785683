import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { toast } from 'react-toastify';

export const getUserTransactions = createAsyncThunk('user/getUserTransaction',
    async ({ token, id, success }, thunkAPI) => {
        if (!id) return;
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/account/transactions/${id ?? ""}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(response)
            success();

            return response.data?.data
        }
        catch (err) {
            toast.error(err.response?.data?.msg);
            console.log(err.response?.data?.msg);
        }
    })


export const getUserData = createAsyncThunk('user/getUserData',
    async ({ token, id, success }, thunkAPI) => {
        if (!id) return;
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/account/${id ?? ""}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(response)
            success();

            return response.data?.data
        }
        catch (err) {
            toast.error(err.response?.data?.msg);
            console.log(err.response?.data?.msg);
        }
    })

export const getAdminData = createAsyncThunk('user/getAdminData',
    async ( token, thunkAPI) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            console.log(response)
            return response.data?.data
        }
        catch (err) {
            console.log(err.response?.data?.msg);
        }
    })

const initialState = {
    userData: {},
    adminData: {}
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: {
        [getUserData.fulfilled]: (state, {payload}) => {
            state.userData = payload
        },
        [getAdminData.fulfilled]: (state, {payload}) => {
            state.adminData = payload
        }
    }
})

export const userSelector = state => state.user
export default userSlice.reducer