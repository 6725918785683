import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Dropdown from '../reusables/dropdown'
import InputField from '../reusables/input';

function QuickReport({show, handleClose, generateReport}) {

    const [state, setState] = useState({
        show: 'Group of Age',
        value: 'Global',
        duration: {
            from:
                "September 2021",
            to:
                "September 2022",
        }
    })

    let groupOptions = [
        "Group of Age",
        "Gender",
        "New Member",
        "Active Member"
    ]

    let valueOptions = [
        "Global",
        "Time Zone",
        "Continent",
        "Country"
    ]

    return (
        <Modal show={ show } onHide={ handleClose } className='modal__custom' centered>
            <div className="hasBorder__bottom sectionText__mid text__light py-4">
                <p className="center mb-0">Quick Report</p>
                <i
                    className="rAlign bi bi-x-lg sectionText__big icon__close clickable"
                    onClick={ handleClose }></i>
            </div>

            <div className="py-4 w-80">
                <div className="row">
                    <div className="col-md-6 my-2">
                        <Dropdown label="What do you want to show?" value={ state.show } options={ groupOptions } long />
                    </div>

                    <div className="col-md-6 my-2">
                        <Dropdown label="Select Value" value={ state.value } options={ valueOptions } long />
                    </div>

                    <div className="col-md-6 my-2">
                        <InputField label="From:" disabled value={ state.duration.from } />
                    </div>

                    <div className="col-md-6 my-2">
                        <InputField label="To:" disabled value={ state.duration.to } />
                    </div>
                </div>

                <div className="alignOnly mt-3">
                    <input type="checkbox" className='checkbox__custom'/>
                    <p className="sectionText text__light mb-0 mx-2">Lifetime</p>
                </div>


                <div className="my-4 center">
                    <button className="btn__default" onClick={generateReport}>Generate Report</button>
                </div>
            </div>
        </Modal>
    )
}

export default QuickReport