import React from 'react'
import FeeInput from '../reusables/feeInput'
import InputField from '../reusables/input'
import Nav from '../reusables/nav'

function FeeSettings() {
    return (
        <div className='container__custom'>
            <Nav pageName='Fee Settings' />

            <div className="alignOnly">
                <button className='btn__default sectionText mx-1'>Save Changes</button>
                <button className='btn__transparent mx-1'>Discard Changes</button>
            </div>

            <div className="row mt-3">
                <div className="col-md-4 my-4">
                    <div className="card__custom h-100">
                        <p className="text__light sectionText">Personal Account</p>

                        <FeeInput value="£1.98" className='pt-4 hasBorder__top' />
                    </div>
                </div>

                <div className="col-md-4 my-4">
                    <div className="card__custom">
                        <p className="text__light sectionText">Gold Account</p>

                        <FeeInput value="£1.98" className='py-4 hasBorder__top' />
                        <FeeInput value="£36.90" className='pt-2' setupFee />
                    </div>
                </div>


                <div className="col-md-4 my-4">
                    <div className="card__custom">
                        <p className="text__light sectionText">Business Account</p>

                        <FeeInput value="£2.97" className='py-4 hasBorder__top' />
                        <FeeInput value="£70" className='pt-2' setupFee />
                    </div>

                </div>

                <div className="col-md-4 my-4">
                    <div className="card__custom">
                        <div className="vAlign py-3 hasBorder__bottom">
                            <p className="sectionText text__light">Exchange Fee</p>
                        </div>

                        <div className="row">
                            <div className="col-md-6 my-3">
                                <InputField label="Crypto / FX pair" value="1.3%" />
                            </div>
                            <div className="col-md-6 my-3">
                                <InputField label="Stablecoin" value="0.8%" />
                            </div>
                        </div>

                        <div className="py-2">
                            <p className="sectionText text__light mb-1">Payment Card Processing Fee</p>
                            <div className="row">
                                <div className="col-md-6 my-1">
                                    <InputField value="3.55%" />
                                </div>
                                <div className="col-md-6 my-1">
                                    <InputField value="£36.90" />
                                </div>
                            </div>
                        </div>

                        <div className="py-2">
                            <p className="sectionText text__light mb-1">Digital Wallet Payments Processing Fee</p>
                            <div className="row">
                                <div className="col-md-6 my-1">
                                    <InputField value="3.55%" />
                                </div>
                                <div className="col-md-6 my-1">
                                    <InputField value="£36.90" />
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <InputField label="Online Banking/ACH Processing Fee" value="3.55%" />
                        </div>
                    </div>
                </div>

                <div className="col-md-4 my-4">
                    <div className="card__custom">
                        <div className="vAlign py-3 hasBorder__bottom mb-4">
                            <p className="sectionText text__light">Trading Fees</p>
                        </div>

                        <p className="mb-0 sectionText text__light">Margin Fees - Opening & Rollover</p>
                        <div className="row">
                            <div className="col-md-6 my-1">
                                <InputField label="U.S Country" value=".01%" />
                            </div>
                            <div className="col-md-6 my-1">
                                <InputField label="Non U.S Country" value=".02%" />
                            </div>
                        </div>

                        <div className="py-2">
                            <p className="sectionText text__light mb-0">Futures</p>
                            <div className="row">
                                <div className="col-md-6 my-1">
                                    <InputField value="0.00%" label="Min" />
                                </div>
                                <div className="col-md-6 my-1">
                                    <InputField value="0.04%" label="Max" />
                                </div>
                            </div>
                        </div>

                        <div className="py-2">
                            <p className="sectionText text__light mb-0">Stable and Pegged Tokens</p>
                            <div className="row">
                                <div className="col-md-6 my-1">
                                    <InputField value="0.00%" label="Min" />
                                </div>
                                <div className="col-md-6 my-1">
                                    <InputField value="0.16%" label="Max" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default FeeSettings
